/* eslint-disable no-unused-vars */
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";

import Logo from "../../../../../../../assets/images/logo_gestao.png";

// console.log(MyFont);

const ListaObrasPDF = (obrasState, date) => {
  const doc = new jsPDF("landscape", "mm", "a4");
  // var width = doc.internal.pageSize.getWidth();
  // var height = doc.internal.pageSize.getHeight();

  // const registosPorPagina = 15;
  const totalPagesExp = "{total_pages_count_string}";
  let numPagesTotal = 0;

  doc.setFont("Lato-Semibold", "normal");

  const headerContent = () => {
    doc.setFont("Lato-Semibold", "normal");
    doc.setFontSize(16);
    doc.text("Lista de Obras", 15, 15, { align: "left" });
    doc.addImage(Logo, 210, 11.5, 10, 3, { align: "left" });
    // doc.addSvgAsImage(Logo1, 150, 11.5, 10, 3, { align: 'left' });
    doc.setFont("Lato-Semibold", "normal");
    doc.setFontSize(18);
    doc.text("Gestão Operacional", 226, 15, { align: "left" });
    doc.setFont("Lato-Semibold", "normal");
    doc.setFontSize(12);
  };

  // headerContent();
  // doc.setFont('Lato-Semibold', 'normal');
  // doc.setFontSize(16);
  // doc.text('Lista de Obras', 15, 15, { align: 'left' });
  // doc.addImage(Logo, 210, 11.5, 10, 3, { align: 'left' });
  // doc.setFont('Lato-Semibold', 'normal');
  // doc.setFontSize(18);
  // doc.text('Gestão Operacional', 226, 15, { align: 'left' });
  // doc.setFont('Lato-Semibold', 'normal');
  // doc.setFontSize(12);

  const headers = [
    {
      dataKey: "id",
      header: "Nº de Obra",
    },
    {
      dataKey: "descricao",
      header: "Descrição",
    },

    {
      dataKey: "dataCriacao",
      header: "Data de Criação",
    },
    {
      dataKey: "idade",
      header: "Idade",
    },
    {
      dataKey: "dataInicial",
      header: "Data Início",
    },
    {
      dataKey: "dataFinal",
      header: "Data Fim",
    },
    {
      dataKey: "dataRealInicio",
      header: "Data Real Início",
    },
    {
      dataKey: "dataRealFim",
      header: "Data Real Fim",
    },
    {
      dataKey: "custo",
      header: "Custo",
    },
    {
      dataKey: "receita",
      header: "Receita",
    },
    {
      dataKey: "resultado",
      header: "Resultado",
    },
  ];

  let body = obrasState;

  // Calculo Total ( ultima row )

  body = [
    ...body.map((el) => [
      el.id,
      el.descricao,
      el.dataCriacao != null
        ? format(new Date(el.dataCriacao), "dd/MM/yyyy")
        : " - ",
      el.idade + " dias",
      el.dataInicial != null
        ? format(new Date(el.dataInicial), "dd/MM/yyyy")
        : " - ",
      el.dataFinal != null
        ? format(new Date(el.dataFinal), "dd/MM/yyyy")
        : " - ",
      el.dataRealInicio != null
        ? format(new Date(el.dataRealInicio), "dd/MM/yyyy")
        : " - ",
      el.dataRealFim != null
        ? format(new Date(el.dataRealFim), "dd/MM/yyyy")
        : " - ",
      el.custo != null
        ? new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          }).format(el.custo)
        : " - ",
      el.receita != null
        ? new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          }).format(el.receita)
        : " - ",
      el.resultado != null
        ? new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          }).format(el.resultado)
        : " - ",
    ]),
  ];

  doc.autoTable({
    columns: headers,
    columnStyles: {
      //id
      0: { cellWidth: 25 },
      //descrição
      1: { cellWidth: 92 },
      //data de criação
      2: { cellWidth: 15 },
      //Idade
      3: { cellWidth: 15 },
      //data inicio
      4: { cellWidth: 15 },
      //data fim
      5: { cellWidth: 15 },
      //data real inicio
      6: { cellWidth: 15 },
      //data real fim
      7: { cellWidth: 15 },
      //custo
      8: { cellWidth: 20 },
      //receita
      9: { cellWidth: 20 },
      //resultado
      10: { cellWidth: 20 },
    },
    body: body,
    startX: 30,
    startY: 25,
    theme: "grid",
    margin: { left: 15, right: 15, top: 25 },
    // tableLineColor: [255, 255, 255],
    // tableLineWidth: 1,
    // padding: { top: 0, right: 0, bottom: 0, left: 0 },
    // styles: {
    //   lineHeight: 0.5, // not working
    // },
    headStyles: {
      font: "Lato-Bold",
      fillColor: [255, 255, 255],
      textColor: [80, 80, 80],
    },
    styles: {
      font: "Lato-Semibold",
      fontSize: 8,
      margins: 0,
      // padding: 1,
      printHeaders: true,
      lineWidth: 0.15,
    },

    didParseCell(dataParseCell) {
      doc.setFont("Lato-Semibold", "normal");
      if (
        (dataParseCell.row.section === "head" ||
          dataParseCell.row.section === "foot") &&
        [
          "id",
          "descricao",
          "dataCriacao",
          "idade",
          "dataInicial",
          "dataFinal",
          "dataRealInicio",
          "dataRealFim",
          "custo",
          "receita",
          "resultado",
        ].includes(dataParseCell.column.dataKey)
      ) {
        doc.setFont("Lato-Semibold", "normal");

        dataParseCell.cell.styles.fillColor = [240, 240, 240];
        dataParseCell.cell.styles.lineColor = [240];
        dataParseCell.cell.styles.margin = 20;
      }
    },
    didDrawPage: (dataPage) => {
      doc.setFont("Lato-Semibold");
      headerContent();
      let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
      if (typeof doc.putTotalPages === "function") {
        footerStr = `${footerStr} de ${totalPagesExp}`;
      }
      doc.setFontSize(10);
      doc.setTextColor("#707070");

      doc.text(
        "JAF Construções / Aprovisionamento / Lista de obras",
        dataPage.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
      doc.text(
        footerStr,
        doc.internal.pageSize.width + 23,
        doc.internal.pageSize.height - 10,
        { align: "right" }
      );
      doc.text(
        date,
        doc.internal.pageSize.width - 40,
        doc.internal.pageSize.height - 10,
        { align: "right" }
      );

      numPagesTotal++;
    },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save("Listagem de Obras.pdf");
  doc.autoPrint();
};

export default ListaObrasPDF;
