/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import { Typography, Box, Card, ListItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import ReactApexChart from "react-apexcharts";
import { CssBaseline } from "@mui/material";
import { Grid } from "@mui/material";
import { Paper } from "@mui/material";
import moment from "moment";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetObrasAHD from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDAction";
import GetObrasAHDSerralharia from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDSerralhariaAction";
import SignInWithToken from "../../../../utils/redux/actions/authWithToken";
import ShowNotification from "../../../../utils/redux/actions/global/notifications/NotificationAction";
import GetObrasAHDEmCurso from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDEmCursoAction";
import GetObrasAHDRealizadasTotalSemana from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDRealizadasTotalSemanaAction";
import GetObrasAHDRealizadasTotal from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDRealizadasTotalActions";
import GetGraficoObrasMes from "../../../../utils/redux/actions/aprovisionamento/obras/GetGraficoObrasMesAction";
import GetGraficoObrasSemana from "../../../../utils/redux/actions/aprovisionamento/obras/GetGraficoObrasSemanaAction";
import GetObrasAHDMediaIdadeRealizadas from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDMediaIdadeRealizadasAction";
import GetObrasAHDEmCursoSerralharia from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDEmCursoSerralhariaAction";
import GetObrasAHDProjetosTotais from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDProjetosTotaisAction";
import GetObrasAHDProjetosTotaisPenalizados from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDProjetosTotaisPenalizadosAction";
import GetObrasAHDProjetosPenalizadosAnoAnterior from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDProjetosPenalizadosAnoAnteriorAction";
import GetObrasAHDProjetosPenalizadosIniciados from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDProjetosPenalizadosIniciadosAction";
import GetObrasAHDProjetosPenalizadosAnoAtual from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAHDProjetosPenalizadosAnoAtualAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  btnFiltrar: {
    color: "#E21450",
    width: "115px",
    height: "40px",
    fontWeight: "900",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },

  evenRow: {
    backgroundColor: "#424242" /* Dark background color for even rows */,
  },

  oddRow: {
    backgroundColor:
      "#323232" /* Slightly darker background color for odd rows */,
  },
  container: {
    display: "flex",
    margin: 20, // Set margin as a percentage of the viewport width
    height: "calc(100vh - 20px)",
  },
  chartColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
  },
  chartGroup: {
    flex: 5,
    display: "flex",
    flexDirection: "column",
  },
  chartCard: {
    flex: 1,
    marginBottom: 20,
    overflow: "hidden",
  },
  chart: {
    flex: 1,
  },
  tableColumn: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },
  tableCard: {
    flex: 1,
    marginBottom: 20,
  },
}));
const customTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    // Default base font size
    fontSize: 10,
    "@media (min-width: 2000px)": {
      fontSize: "1.4rem", // Adjusted font size for screens wider than 1920px (4K monitors)
    },
    h1: {
      fontSize: 50, // Default responsive font size for h1
      "@media (min-width: 2000px)": {
        fontSize: "6rem", // Adjusted font size for screens wider than 1920px (4K monitors)
      },
    },
    h2: {
      fontSize: 18, // Default responsive font size for h2
      "@media (min-width: 2000px)": {
        fontSize: "2.4rem", // Adjusted font size for screens wider than 1920px (4K monitors)
      },
    },
    h3: {
      fontSize: 13, // Default responsive font size for h3
      "@media (min-width: 2000px)": {
        fontSize: "1.9rem", // Adjusted font size for screens wider than 1920px (4K monitors)
      },
    },
    h4: {
      fontSize: 12, // Default responsive font size for h4
      "@media (min-width: 2000px)": {
        fontSize: "1.7rem", // Adjusted font size for screens wider than 1920px (4K monitors)
      },
    },
    h5: {
      fontSize: 10, // Default responsive font size for h5
      "@media (min-width: 2000px)": {
        fontSize: "1.4rem", // Adjusted font size for screens wider than 1920px (4K monitors)
      },
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0px",
          paddingLeft: "16px",
          "@media (min-width: 0px)": {
            minHeight: 0,
            padding: "0px",
            paddingLeft: "16px",
          },
          "@media (min-width: 600px)": {
            minWidth: 0,
            padding: "0px",
            paddingLeft: "16px",
          },
        },
      },
    },
    MUIDataTableBody: {
      styleOverrides: {
        emptyTitle: {
          fontSize: "18px",
          paddingTop: "0px",
          paddingBottom: "0px",
          "@media (min-width: 2000px)": {
            fontSize: "2.2rem", // Adjusted font size for screens wider than 1920px (4K monitors)
          },
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          paddingTop: "0px",
          paddingBottom: "0px",
          "@media (min-width: 2000px)": {
            fontSize: "2.2rem", // Adjusted font size for screens wider than 1920px (4K monitors)
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          fontSize: "18px",
          "@media (min-width: 2000px)": {
            fontSize: "2.2rem", // Adjusted font size for screens wider than 1920px (4K monitors)
          },
        },
        fixedHeader: {
          padding: "0px",
          paddingLeft: "16px",
        },
        toolButton: {
          // background: 'lightgreen',
          padding: "0px",
          margin: "0px",
          // lineHeight: 0,
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          "&:nth-child(even)": {
            backgroundColor: "#3c3c3c",
          },
        },
      },
    },
  },
});

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const DashboardAHDSerralharia = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const parts = location.pathname.split("/");
  const dashtoken = parts[2];

  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const dimensions = useWindowDimensions();

  const user = useSelector((state) => state.user && state.user.permissoes);
  const userToken = useSelector(
    (state) => state.user && state.user.auth_utilizadores_url_key
  );
  const dashboardahdserralharia =
    location.pathname === `/dashboardahdserralharia/${userToken}`;

  const loginWithToken = () => {
    const message = {
      isOpen: true,
      message: "Utilizador logado com sucesso!!",
      type: "success",
    };
    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!!",
      type: "error",
    };

    const ydata = [
      {
        token: dashtoken,
      },
    ];

    dispatch(
      SignInWithToken(
        ydata,
        navigate,
        ShowNotification,
        message,
        errorMessage,
        parts[1]
      )
    ).then(() => {
      fetchObras();
      fetchObrasSerralharia();
      fetchIndicadores();
      fetchGraficos();
    });
  };

  // ############################################################## //
  // ####################### Dados Indicadores #################### //
  // ############################################################## //

  const emCurso = useSelector((state) => state.obras.obrasAHDEmCurso);
  const emCursoSerralharia = useSelector(
    (state) => state.obras.obrasAHDEmCursoSerralharia
  );
  const realizadasTotal = useSelector(
    (state) => state.obras.obrasAHDRealizadasTotal
  );
  const realizadasTotalSemana = useSelector(
    (state) => state.obras.obrasAHDRealizadasTotalSemana
  );
  const mediaIdadeRealizadas = useSelector(
    (state) => state.obras.obrasAHDMediaIdadeRealizadas
  );
  const projetosTotais = useSelector(
    (state) => state.obras.obrasAHDProjetosTotais
  );
  const projetosTotaisPenalizados = useSelector(
    (state) => state.obras.obrasAHDProjetosTotaisPenalizados
  );
  const projetosPenalizadosAnoAnterior = useSelector(
    (state) => state.obras.obrasAHDProjetosPenalizadosAnoAnterior
  );
  const projetosPenalizadosAnoAtual = useSelector(
    (state) => state.obras.obrasAHDProjetosPenalizadosAnoAtual
  );
  const projetosPenalizadosIniciados = useSelector(
    (state) => state.obras.obrasAHDProjetosPenalizadosIniciados
  );

  // ############################################################## //
  // ######################### Dados Obras ######################## //
  // ############################################################## //

  const obrasState = useSelector((state) => state.obras.obrasAHD);
  const obrasSerralhariaState = useSelector(
    (state) => state.obras.obrasAHDSerralharia
  );
  const obrasSplit = obrasState.slice(0, 25);
  obrasSplit.sort((a, b) => b.idade - a.idade);

  const obrasSplit2 = obrasSerralhariaState.slice(0, 10);
  obrasSplit2.sort((a, b) => b.idade - a.idade);

  // ############################################################## //
  // ######################## Dados Gráficos ###################### //
  // ############################################################## //

  const obrasSemanal = useSelector((state) => state.obras.graficoObrasSemanal);
  const uniqueWeeks = {};

  obrasSemanal.forEach((item) => {
    const weekNumber = item.week_number;
    if (!uniqueWeeks[weekNumber]) {
      uniqueWeeks[weekNumber] = { ...item };
    } else {
      uniqueWeeks[weekNumber].total2 = item.total;
    }
  });

  // Convert uniqueWeeks object back to array
  const resultArray = Object.values(uniqueWeeks).map((item) => {
    // If total2 is not defined, set it to 0
    if (!item.total2) {
      item.total2 = 0;
    }
    return item;
  });

  const firstArray = resultArray.map((sem) => sem.total);
  const secondArray = resultArray.map((sem) => sem.total2);
  const totalpenSemanalFilter = obrasSemanal.map((sem) => sem.totalPen);
  const totalprojSemanalFilter = obrasSemanal.map((sem) => sem.totalProj);
  const obrasSemanalLegenda = obrasSemanal.map((sem) => {
    return "Sem." + sem.week_number + "/" + sem.year;
  });

  const obrasMensal = useSelector((state) => state.obras.graficoObrasMensal);
  const obrasMensalFilter = obrasMensal.map((mes) => ({
    mes: JSON.stringify(mes.ano + "-" + mes.mes + "-" + "1"),
    total: mes.total,
    totalPen: mes.totalPen,
  }));

  const mensalMeses = obrasMensalFilter.map((mes) =>
    moment(mes.mes, "YYYY-MM-DD").format("MMM/YY")
  );
  const totalobrasMensalFilter = obrasMensalFilter.map((mes) => mes.total);
  const totalpenMensalFilter = obrasMensalFilter.map((mes) => mes.totalPen);
  console.log(mensalMeses);

  // ############################################################## //
  // ################# MUI DataTable Columns Obras  ############### //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "Nº de Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "100px" },
        // }),
      },
    },
    {
      name: "descricao",
      label: "Nome de Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "350px" },
        // }),
        // customBodyRender: (value) => {
        //   // Set the maximum length you want for the description
        //   const maxLength = 50;

        //   // Check if the string is longer than the maximum length
        //   if (value.length > maxLength) {
        //     // Truncate the string and add ellipsis
        //     return `${value.substring(0, maxLength)}...`;
        //   }

        //   // If the string is within the maximum length, display it as is
        //   return value;
        // },
      },
    },
    {
      name: "idade",
      label: "Idade",
      options: {
        display: "true",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const dri = tableMeta.rowData[3];

          return (
            <div className="table-btns-tiposatrasos">
              {dri ? (
                value > 90 ? (
                  <div style={{ color: "red" }}>{value}</div>
                ) : (
                  <>{value}</>
                )
              ) : (
                <>{0}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataRealInicio",
      label: "Inicio Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataRealFim",
      label: "Fim Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idadeReal",
      label: "Tempo execução",
      options: {
        display: "true",
        filter: true,
        // sort: true,
        // customBodyRender: (value, tableMeta) => {
        //   const dri = tableMeta.rowData[3];

        //   return (
        //     <div className="table-btns-tiposatrasos">
        //       {dri ? (
        //         value > 90 ? (
        //           <div style={{ color: "red" }}>{value}</div>
        //         ) : (
        //           <>{value}</>
        //         )
        //       ) : (
        //         <>{0}</>
        //       )}
        //     </div>
        //   );
        // },
      },
    },
    {
      name: "last_jaf_work_permit_date_start",
      label: "Inicio WP",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "last_jaf_work_permit_date_end",
      label: "Fim WP",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "daysToExpireWP",
      label: "Dias WP",
      options: {
        display: "true",
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "80px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          const days = value;
          let color;

          switch (true) {
            case days > 5:
              color = "green";
              break;
            case days >= 3 && days < 5:
              color = "orange";
              break;
            case days < 3:
              color = "red";
              break;
            default:
              // Handle other cases or set a default color
              color = "default";
          }

          return (
            <>
              {value == null ? (
                " - "
              ) : value == "Expirou" ? (
                <Typography sx={{ color: "red", fontSize: "10px" }}>
                  {value}
                </Typography>
              ) : value == 1 ? (
                <Typography sx={{ color: "red", fontSize: "10px" }}>
                  {value}
                </Typography>
              ) : (
                <Typography sx={{ color: color, fontSize: "10px" }}>
                  {value}
                </Typography>
              )}
            </>
          );
        },
      },
    },
  ];

  // ############################################################### //
  // ############### MUI DataTable Columns Serralharia  ############ //
  // ############################################################### //

  const columnsSer = [
    {
      name: "id",
      label: "Nº de Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "100px" },
        // }),
      },
    },
    {
      name: "descricao",
      label: "Nome de Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "350px" },
        // }),
        // customBodyRender: (value) => {
        //   // Set the maximum length you want for the description
        //   const maxLength = 50;

        //   // Check if the string is longer than the maximum length
        //   if (value.length > maxLength) {
        //     // Truncate the string and add ellipsis
        //     return `${value.substring(0, maxLength)}...`;
        //   }

        //   // If the string is within the maximum length, display it as is
        //   return value;
        // },
      },
    },
    // {
    //   name: "vendorNameSer",
    //   label: "Empresa",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     // setCellProps: () => ({
    //     //   style: { minWidth: "350px" },
    //     // }),
    //     // customBodyRender: (value) => {
    //     //   // Set the maximum length you want for the description
    //     //   const maxLength = 50;

    //     //   // Check if the string is longer than the maximum length
    //     //   if (value.length > maxLength) {
    //     //     // Truncate the string and add ellipsis
    //     //     return `${value.substring(0, maxLength)}...`;
    //     //   }

    //     //   // If the string is within the maximum length, display it as is
    //     //   return value;
    //     // },
    //   },
    // },
    // {
    //   name: "obraSerobs",
    //   label: "Observações",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     // setCellProps: () => ({
    //     //   style: { minWidth: "350px" },
    //     // }),
    //     // customBodyRender: (value) => {
    //     //   // Set the maximum length you want for the description
    //     //   const maxLength = 50;

    //     //   // Check if the string is longer than the maximum length
    //     //   if (value.length > maxLength) {
    //     //     // Truncate the string and add ellipsis
    //     //     return `${value.substring(0, maxLength)}...`;
    //     //   }

    //     //   // If the string is within the maximum length, display it as is
    //     //   return value;
    //     // },
    //   },
    // },
    {
      name: "idade",
      label: "Idade",
      options: {
        display: "true",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value > 90 ? (
                <div style={{ color: "red" }}>{value}</div>
              ) : (
                <>{value}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataInicioObraSer",
      label: "Inicio Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataFimObraSer",
      label: "Fim Obra",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idadeReal",
      label: "Tempo execução",
      options: {
        display: "true",
        filter: true,
        // sort: true,
        // customBodyRender: (value, tableMeta) => {
        //   return (
        //     <div className="table-btns-tiposatrasos">
        //       {value > 90 ? (
        //         <div style={{ color: "red" }}>{value}</div>
        //       ) : (
        //         <>{value}</>
        //       )}
        //     </div>
        //   );
        // },
      },
    },
    {
      name: "last_jaf_work_permit_date_start",
      label: "Inicio WP",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "last_jaf_work_permit_date_end",
      label: "Fim WP",
      options: {
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { maxWidth: "40px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "daysToExpireWP",
      label: "Dias WP",
      options: {
        display: "true",
        filter: true,
        sort: true,
        // setCellProps: () => ({
        //   style: { minWidth: "80px" },
        // }),

        customBodyRender: (value, tableMeta) => {
          const days = value;
          let color;

          switch (true) {
            case days > 5:
              color = "green";
              break;
            case days >= 3 && days < 5:
              color = "orange";
              break;
            case days < 3:
              color = "red";
              break;
            default:
              // Handle other cases or set a default color
              color = "default";
          }

          return (
            <>
              {value == null ? (
                " - "
              ) : value == "Expirou" ? (
                <Typography sx={{ color: "red", fontSize: "10px" }}>
                  {value}
                </Typography>
              ) : value == 1 ? (
                <Typography sx={{ color: "red", fontSize: "10px" }}>
                  {value}
                </Typography>
              ) : (
                <Typography sx={{ color: color, fontSize: "10px" }}>
                  {value}
                </Typography>
              )}
            </>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // #################### MUI DataTable Options  ################## //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    tableBodyHeight,
    responsive,
    selectableRows: "none",
    viewColumns: false,
    pagination: false,
    download: false,
    search: false,
    print: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Nenhuma obra encontrada",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // ################## Gráfico de barraas exemplo ################ //
  // ############################################################## //

  const chartData = {
    series: [
      {
        name: "Obras",
        data: totalobrasMensalFilter,
      },
      {
        name: "Penalizações",
        data: totalpenMensalFilter,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#00E396", "#DE0000"],
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      title: {
        text: "Nº de obras executadas nos últimos meses",
        style: {
          color: "#b8b8b8",
          fontSize: dimensions.width >= 2000 ? "30px" : "14px",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: dimensions.width >= 2000 ? "30px" : "14px",
        },
      },
      legend: {
        labels: {
          colors: "#b8b8b8",
        },
        fontSize: dimensions.width >= 2000 ? "30px" : "14px",
      },
      tooltip: {
        theme: "dark",
      },
      // plotOptions: {
      //   bar: {
      //     borderRadius: 5,
      //   },
      // },
      xaxis: {
        categories: mensalMeses,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Nº obras",
          style: {
            color: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
      },
    },
  };

  // ############################################################## //
  // ################## Gráfico de barraas exemplo ################ //
  // ############################################################## //

  const chartData2 = {
    series: [
      {
        name: "Obras",
        group: "obras",
        data: firstArray,
      },
      {
        name: "Obras mês seguinte",
        group: "obras",
        data: secondArray,
      },
      {
        name: "Projeções",
        group: "proj",
        data: totalprojSemanalFilter,
      },
      {
        name: "Penalizações",
        group: "penal",
        data: totalpenSemanalFilter,
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#00E396", "#80F1CB", "#008FFB", "#DE0000"],
      // plotOptions: {
      //   bar: {
      //     borderRadius: 2,
      //   },
      // },
      title: {
        text: "Nº de obras executadas nas últimas semanas",
        style: {
          color: "#b8b8b8",
          fontSize: dimensions.width >= 2000 ? "30px" : "14px",
        },
      },
      legend: {
        fontSize: dimensions.width >= 2000 ? "30px" : "14px",
        labels: {
          colors: "#b8b8b8",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: dimensions.width >= 2000 ? "30px" : "14px",
        },
        position: "center", // or 'center' or 'bottom'
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: obrasSemanalLegenda,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Nº de obras",
          style: {
            color: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
        labels: {
          style: {
            colors: "#b8b8b8",
            fontSize: dimensions.width >= 2000 ? "25px" : "14px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  const fetchObrasSerralharia = () => {
    dispatch(GetObrasAHDSerralharia());
  };

  const fetchObras = () => {
    dispatch(GetObrasAHD());
  };

  const fetchIndicadores = () => {
    dispatch(GetObrasAHDEmCurso());
    dispatch(GetObrasAHDRealizadasTotal());
    dispatch(GetObrasAHDRealizadasTotalSemana());
    dispatch(GetObrasAHDMediaIdadeRealizadas());
    dispatch(GetObrasAHDEmCursoSerralharia());
    dispatch(GetObrasAHDProjetosTotais());
    dispatch(GetObrasAHDProjetosTotaisPenalizados());
    dispatch(GetObrasAHDProjetosPenalizadosAnoAtual());
    dispatch(GetObrasAHDProjetosPenalizadosAnoAnterior());
    dispatch(GetObrasAHDProjetosPenalizadosIniciados());
  };

  const fetchGraficos = () => {
    dispatch(GetGraficoObrasMes());
    dispatch(GetGraficoObrasSemana());
  };

  // ############################################################## //
  // ####################### Weather API Data ##################### //
  // ############################################################## //

  const [weather, setWeather] = useState();
  const todaysdate = moment().format("YYYY-MM-DD");
  const nextweeksdate = moment().add(6, "days").format("YYYY-MM-DD");
  const temperaturas =
    weather &&
    weather.days.map((day) => {
      return {
        day: moment(day.datetime).format("DD/MM"),
        weekday: moment(day.datetime).format("dddd").split("-")[0],
        min: Math.round(day.tempmin) + "°",
        max: Math.round(day.tempmax) + "°",
        windspeed: Math.round(day.windspeed) + "km/h",
        winddir: Math.round(day.winddir) + "°",
        icon: day.icon,
      };
    });

  const [atualizacao, setAtualizacao] = useState(new Date());

  useEffect(() => {
    // Perform login initially
    loginWithToken();

    // Set up interval for subsequent fetches every hour
    const fetchObrasData = () => {
      // Fetch obras data
      fetchObras();

      // Fetch serralharia data
      fetchObrasSerralharia();

      //Fetch quadro de indicadores
      fetchIndicadores();

      //Fetch gráficos
      fetchGraficos();

      // Update the atualizacao state with the current date and time
      setAtualizacao(new Date());
    };

    const intervalId = setInterval(fetchObrasData, 300000);

    // Function to fetch weather data
    const fetchWeatherData = () => {
      fetch(
        `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/Lisboa/${todaysdate}/${nextweeksdate}?unitGroup=metric&key=UN9HVW4JPJFQWGZLYHS7MGM6D&contentType=json`
      )
        .then((response) => response.json())
        .then((json) => setWeather(json))
        .catch((error) => console.error(error));
    };

    // Initial fetch when the component mounts
    fetchWeatherData();

    // Set up interval for subsequent fetches every hour
    const weatherIntervalId = setInterval(fetchWeatherData, 300000);

    // Clean up intervals when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearInterval(weatherIntervalId);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div
      style={{
        paddingTop: "40px",
        display: "flex",
        height: "94vh",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {user &&
        user.includes("APP_DASHBOARD_AHD") &&
        dashboardahdserralharia ? (
          <div className={classes.container}>
            <Grid container>
              {/* Chart Column */}
              <Grid item className={classes.chartColumn}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "60%",
                      marginBottom: 3,
                    }}
                  >
                    <Paper
                      sx={{
                        display: "flex",
                        flex: 1,
                        height: "48%",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 2,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {projetosTotais}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Projetos <br />
                          totais
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {emCurso}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Em Curso Construção Civil
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {emCursoSerralharia}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Em Curso Serralharia
                        </Typography>
                      </Box>
                    </Paper>
                    <Paper
                      sx={{
                        display: "flex",
                        flex: 1,
                        height: "48%",
                        justifyContent: "space-around",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {projetosTotaisPenalizados}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Penalizados <br /> totais
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {projetosPenalizadosAnoAtual}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Penalizados <br /> {moment().year()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "30%",
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {projetosPenalizadosAnoAnterior}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Penalizados <br /> {moment().year() - 1}
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                  <Box sx={{ width: "38%", marginBottom: 3 }}>
                    <Paper
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: 20,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {mediaIdadeRealizadas}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Média Idade (Ano)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {realizadasTotal}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Realizadas Total (Ano)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {realizadasTotalSemana}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Esta Semana
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {projetosPenalizadosIniciados}
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Projetos penalizados Iniciados
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "900",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          -
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "900", mb: 1 }}
                        >
                          Em Produção
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                </Box>
                <Paper
                  className={classes.chartCard}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                  style={{ flex: 2 }}
                >
                  {temperaturas &&
                    temperaturas.map((element, index) => {
                      const src = require(
                        `../../../../assets/icons/${element.icon}.svg`
                      );
                      return (
                        <Box
                          sx={{
                            m: 1,
                            p: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            border: index == 0 ? "1px solid #fff" : null,
                            borderRadius: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              height: "10%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="h2"
                              sx={{ fontWeight: "bold" }}
                            >
                              {element.max}
                            </Typography>
                            /<Typography variant="h3">{element.min}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              height: "20%",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FeatherIcon
                                icon={"wind"}
                                size={dimensions.width >= 2000 ? 20 : 12}
                                color={"#b8b8b8"}
                              />

                              <Typography
                                variant="h5"
                                sx={{
                                  color: "#b8b8b8",
                                  ml: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {element.windspeed}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <FeatherIcon
                                icon={"compass"}
                                color={"#b8b8b8"}
                                size={dimensions.width >= 2000 ? 20 : 12}
                              />
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "#b8b8b8",
                                  ml: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {element.winddir}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40%",
                            }}
                          >
                            <img
                              src={src}
                              alt={element.icon}
                              height={"100%"}
                              width={"100%"}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "25%",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h2"
                              sx={{
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {element.weekday}
                            </Typography>
                            <Typography
                              variant="h3"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {element.day}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </Paper>
                <Paper className={classes.chartCard} style={{ flex: 3 }}>
                  <ReactApexChart
                    options={chartData2.options}
                    series={chartData2.series}
                    type="bar"
                    height="100%"
                    width="100%"
                  />
                </Paper>
                <Paper className={classes.chartCard} style={{ flex: 3 }}>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height="100%"
                    width="100%"
                  />
                </Paper>
              </Grid>

              {/* Table Column */}
              <Grid item className={classes.tableColumn}>
                {/* <Paper className={classes.tableCard} style={{ flex: 1 }}>
                  <MUIDataTable
                    title={
                      <Typography variant="h3"> Construção Civil</Typography>
                    }
                    data={obrasSplit || []}
                    // data={[]}
                    columns={columns}
                    options={{ ...options, tableBodyMaxHeight: "100%" }}
                  />
                </Paper> */}
                <Paper className={classes.tableCard} style={{ flex: 1 }}>
                  <MUIDataTable
                    title={<Typography variant="h3">Serralharia</Typography>}
                    data={obrasSplit2 || []}
                    // data={[]}
                    columns={columnsSer}
                    options={{ ...options, tableBodyMaxHeight: "100%" }}
                  />
                </Paper>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  Última atualização às{" "}
                  {moment(atualizacao).format("hh:mm DD-MM-yyyy")}
                </Typography>
              </Box>
            </Grid>
          </div>
        ) : (
          navigate("/dashboard")
        )}
      </ThemeProvider>
    </div>
  );
};

export default DashboardAHDSerralharia;
