/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import MUIDataTable from "mui-datatables";
import { Paper, IconButton, Typography, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import Instance from "../../../../utils/Axios";
import BASE_URL from "../../../../utils/BASE_URL";

// Components
import SideDrawer from "./components/drawer/drawer";
// import FiltersObras from './components/filters';
import FiltersObrasCheck from "./components/filters/indexCheck";
import CustomToolbar from "./components/TableToolbar/customToolbar";
import FooterLinks from "../../../../Global/Components/Footer";

//Icons
import FeatherIcon from "feather-icons-react";

//Actions
import GetObras from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasAction";
import GetObra from "../../../../utils/redux/actions/aprovisionamento/obras/GetObraAction";
//--filters
import GetEstadosProjeto from "../../../../utils/redux/actions/aprovisionamento/filters/GetEstadosProjetoAction";
import GetEstadosSubProjeto from "../../../../utils/redux/actions/aprovisionamento/filters/GetEstadosSubProjetoAction";
import GetAeroportos from "../../../../utils/redux/actions/global/filters/GetAeroportosAction";
import GetDiretoresObraProjeto from "../../../../utils/redux/actions/aprovisionamento/filters/GetDiretoresObraProjetoAction";
//--estados
import GetEstadosControlo from "../../../../utils/redux/actions/aprovisionamento/estados/GetEstadosControlo";
import GetEstadosEspecialidade from "../../../../utils/redux/actions/aprovisionamento/estados/GetEstadosEspecialidade";
import GetEstadosMaterial from "../../../../utils/redux/actions/aprovisionamento/estados/GetEstadosMaterial";
import GetObrasMateriais from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasMateriaisAction";
import GetObrasTrabalhos from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasTrabalhosAction";
import GetObrasEmpresas from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasEmpresasAction";
import GetObrasUnidMedidas from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasUnidMedidasAction";
import GetObrasTipoDocs from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasTipoDocsAction";
import GetObrasEstadosEspecialidades from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasEstadosEspecialidadesAction";
import GetObrasEquipamentos from "../../../../utils/redux/actions/aprovisionamento/obras/GetObrasEquipamentosAction";
import GetObrasFotos from "../../../../utils/redux/actions/aprovisionamento/obras/GetObraFotosAction";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      width: "25ch",
    },
  },
  stateLabel: {
    textTransform: "none",
    transition: "0.2s",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px",
  },

  paperFilters: {
    background: "transparent",
    padding: "20px 0px",
    boxShadow: "none",
  },
}));

function formatDate(date) {
  // Format the date according to your requirements
  const formattedDate = format(new Date(date), "dd/MM/yyyy"); // Implement the formatting logic here
  return formattedDate;
}

const ColorStateCube = ({ color }) => {
  return (
    <div
      style={{
        height: "15px",
        width: "15px",
        backgroundColor: color,
        marginRight: "10px",
        borderRadius: "3px",
      }}
    />
  );
};

// ############################################################## //
// #####################  Window Dimensions  #################### //
// ############################################################## //

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Obras = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");
  const dimensions = useWindowDimensions();

  const [state, setState] = useState({
    right: false,
  });

  const user = useSelector((state) => state.user);

  const obrasState = useSelector((state) => state.obras.obras);
  const ficheirosState = useSelector((state) => state.obras.ficheiros);
  const estadoProjeto = useSelector((state) => state.obras.estadosProjeto);
  const estadoSubProjeto = useSelector(
    (state) => state.obras.estadosSubProjeto
  );
  const aeroportos = useSelector((state) => state.filters.aeroportos);
  const controlo = useSelector((state) => state.obras.estadosControlo);
  const diretorObra = useSelector((state) => state.obras.diretoresObraProjeto);

  let estadosProjetoPreSelectedId = useSelector(
    (state) => state.obras.estadosProjetoPreSelected
  );
  let estadosSubProjetoPreSelectedId = useSelector(
    (state) => state.obras.estadosSubProjetoPreSelected
  );
  let diretorObraPreSelectedId = useSelector(
    (state) => state.obras.diretorObraPreSelected
  );
  let aeroportoPreSelectedId = useSelector(
    (state) => state.obras.aeroportosPreSelected
  );
  let controloPreSelectedId = useSelector(
    (state) => state.obras.controloPreSelected
  );

  const aeroIds = aeroportoPreSelectedId.map((aero) => aero.id);

  useEffect(() => {
    const eProjetosId = estadosProjetoPreSelectedId.map((ep) => ep.id);
    const eSubProjetosId = estadosSubProjetoPreSelectedId.map((ep) => ep.id);
    let aeroportoId = [];
    const controloId = controloPreSelectedId.map((ep) => ep.id);
    const diretorObraId =
      user && user.perfilId == 3
        ? user.diretorId
        : diretorObraPreSelectedId && diretorObraPreSelectedId.id;
    if (user && user.aeroportoFilter != null) {
      if (aeroIds.includes(user.aeroportoFilter.id) == false) {
        dispatch({
          type: "ESTADOS_AEROPORTO_PRE_SELECTED",
          payload: [user && user.aeroportoFilter],
        });
        aeroportoId = [user && user.aeroportoFilter].map((ep) => ep.id);
      } else {
        dispatch({
          type: "ESTADOS_AEROPORTO_PRE_SELECTED",
          payload: aeroportoPreSelectedId,
        });
        aeroportoId = aeroportoPreSelectedId.map((ep) => ep.id);
      }
    } else {
      dispatch({
        type: "ESTADOS_AEROPORTO_PRE_SELECTED",
        payload: [],
      });
    }

    fetchObras(
      eProjetosId,
      eSubProjetosId,
      aeroportoId,
      controloId,
      diretorObraId
    );
  }, []);

  const [drawerState, setDrawerState] = useState("");

  const toggleDrawer = (anchor, open, obra, dState) => {
    if (open == false) {
      setState({ ...state, [anchor]: open });
      dispatch({ type: "GET_OBRA", payload: [] });
      // setObraSelecionada(null);
      setDrawerState("");
    }
    if (open == true) {
      // setObraSelecionada(null);
      setDrawerState(dState);
      setState({ ...state, [anchor]: open });
      fetchObra(obra);
    }
  };

  const fetchObra = (obra) => {
    const obraId = obra;
    dispatch(GetObra(obraId));
    dispatch(GetObrasFotos(obraId));
  };

  const fetchObras = (
    estadosProjetoPreSelectedId,
    estadosSubProjetoPreSelectedId,
    aeroportosId,
    estadosControloId,
    diretorObraId
  ) => {
    dispatch(
      GetObras(
        estadosProjetoPreSelectedId,
        estadosSubProjetoPreSelectedId,
        aeroportosId,
        estadosControloId,
        diretorObraId
      )
    );
  };

  // FILTERS
  const fetchEstadosProjeto = () => {
    dispatch(GetEstadosProjeto());
  };
  const fetchEstadosSubProjeto = () => {
    dispatch(GetEstadosSubProjeto());
  };
  const fetchAeroportos = () => {
    dispatch(GetAeroportos());
  };
  const fetchDiretoresObra = () => {
    dispatch(GetDiretoresObraProjeto());
  };

  useEffect(() => fetchEstadosProjeto(), []);
  useEffect(() => fetchEstadosSubProjeto(), []);
  useEffect(() => fetchDiretoresObra(), []);

  // OBRAS - ESTADOS
  const fetchEstadosControlo = () => {
    dispatch(GetEstadosControlo());
  };
  const fetchEstadosEspecialidade = () => {
    dispatch(GetEstadosEspecialidade());
  };
  const fetchEstadosMaterial = () => {
    dispatch(GetEstadosMaterial());
  };
  const fetchObrasMateriais = () => {
    dispatch(GetObrasMateriais());
  };
  const fetchObrasTrabalhos = () => {
    dispatch(GetObrasTrabalhos());
  };
  const fetchObrasEmpresas = () => {
    dispatch(GetObrasEmpresas());
  };
  const fetchObrasUnidMedidas = () => {
    dispatch(GetObrasUnidMedidas());
  };
  const fetchTiposDocs = () => {
    dispatch(GetObrasTipoDocs());
  };
  const fetchEspecialidadesEstados = () => {
    dispatch(GetObrasEstadosEspecialidades());
  };
  const fetchObrasEquipamentos = () => {
    dispatch(GetObrasEquipamentos());
  };

  useEffect(() => {
    fetchEstadosControlo();
    fetchEstadosEspecialidade();
    fetchEstadosMaterial();
    fetchAeroportos();
    fetchObrasMateriais();
    fetchObrasTrabalhos();
    fetchObrasEmpresas();
    fetchObrasUnidMedidas();
    fetchTiposDocs();
    fetchEspecialidadesEstados();
    fetchObrasEquipamentos();
  }, []);

  // ############################################################## //
  // ###################  Render Cores Estados  ################### //
  // ############################################################## //

  const renderSwitch = (param) => {
    switch (param) {
      case "1":
        return <ColorStateCube color="#4CB64C" />;
      case "2":
        return <ColorStateCube color="#FFD500" />;
      case "3":
        return <ColorStateCube color="#FF5B5B" />;
      default:
        return null;
    }
  };

  // ############################################################## //
  // ###################  MUI Datatable columns  ################## //
  // ############################################################## //

  const columns = [
    {
      name: "id",
      label: "Nº de Obra",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px" },
        }),
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "300px" },
        }),
      },
    },
    {
      name: "dataCriacao",
      label: "Data de Criação",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idade",
      label: "Idade",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
      },
    },
    {
      name: "dataInicial",
      label: "Data Início",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataFinal",
      label: "Data Fim",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "idadeReal",
      label: "Idade Real",
      options: {
        display: "false",
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value == null ? (
                " - "
              ) : value == 1 ? (
                <Typography>{value} dia</Typography>
              ) : (
                <Typography>{value} dias</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "dataRealInicio",
      label: "Data Real Início",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "dataRealFim",
      label: "Data Real Fim",
      options: {
        filter: true,
        sort: true,
        customHeadRender: (columnMeta, handleToggleColumnSort, options) => {
          const sortDirection = options.direction || null;
          const columnName = options.name || null;
          console.log(options);
          return (
            <>
              <th
                onClick={() => handleToggleColumnSort(columnMeta.index)}
                style={{
                  cursor: "pointer",
                  position: "sticky",
                  top: "0px",
                  zIndex: "100",
                  background: "#F1F1F1",
                }}
              >
                <span>
                  <Tooltip title="Custom tooltip for Name column">
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {columnMeta.label}
                        {columnName == columnMeta.name &&
                          sortDirection === "asc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-up"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                        {columnName == columnMeta.name &&
                          sortDirection === "desc" && (
                            <span>
                              <FeatherIcon
                                icon="arrow-down"
                                size={18}
                                color="#929CA6"
                              />
                            </span>
                          )}
                      </div>
                    </div>
                  </Tooltip>
                </span>
              </th>
            </>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy")}</>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "custo",
      label: "Custo",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "receita",
      label: "Receita",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "resultado",
      label: "Resultado",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "estadosProjetosDescricao",
      label: "Estado de Projeto",
      options: {
        filter: false,
        sort: true,
        display: false,

        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "Aberto":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );
            case "Fechado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#D6DFE8" />
                  {value}
                </div>
              );
            case "Proposta":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#E21450" />
                  {value}
                </div>
              );
            default:
              return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: "estadosSubProjetosDescricao",
      label: "SubProjeto",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          switch (value) {
            case "-":
              return <div className={classes.stateLabel}>{value}</div>;
            case "Aberto":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {/* cenas */}
                  {value}
                </div>
              );
            case "Iniciado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );
            case "Executado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#4CB64C" />
                  {value}
                </div>
              );
            case "Terminado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#929CA6" />
                  {value}
                </div>
              );
            case "Fechado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#D6DFE8" />
                  {value}
                </div>
              );
            case "Suspenso":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#FF8521" />
                  {value}
                </div>
              );
            default:
              return <div>{value}</div>;
          }
        },
      },
    },
    {
      name: "estadosControloDescricao",
      label: "Controlo",
      options: {
        filter: false,
        sort: true,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          // const o = obrasState[tableMeta.rowIndex];

          // console.log(obrasState);

          switch (value) {
            case "Aprovisionado":
              return (
                <div className={classes.stateLabel}>
                  {/* <FaRegCheckCircle style={{ marginRight: '8px' }} /> */}
                  {/* {o.estadosProjetosDescricao} */}
                  <ColorStateCube color="#D6DFE8" />
                  {value}
                </div>
              );

            case "Em Curso":
              return (
                <div className={classes.stateLabel}>
                  {/* <FaRegCheckCircle style={{ marginRight: '8px' }} /> */}
                  <ColorStateCube color="#FFD500" />
                  {value}
                </div>
              );

            case "Planeado":
              return (
                <div className={classes.stateLabel}>
                  <ColorStateCube color="#54B7D3" />
                  {value}
                </div>
              );

            default:
              return (
                <div>
                  {/* <FaRegCheckCircle style={{ marginRight: '8px' }} /> */}
                  {value}
                </div>
              );
          }
        },
      },
    },

    {
      name: "estadosControloCor",
      label: "estado",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "estadodt",
      label: "Téc.",
      options: {
        filter: false,
        sort: true,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {renderSwitch(value)}
            </div>
          );
        },
      },
    },
    {
      name: "estadodc",
      label: "Com.",
      options: {
        filter: false,
        sort: true,
        // filterType: 'custom',
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {renderSwitch(value)}
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[0];
          const filesLength = tableMeta.rowData[19];

          const userEditar =
            user &&
            user.permissoes &&
            user.permissoes.includes("APP_CONSTRUCOES_EDITAR");
          const userConsultar =
            user &&
            user.permissoes &&
            user.permissoes.includes("APP_CONSTRUCOES_CONSULTA");

          if (userEditar === true) {
            return (
              <div style={{ display: "flex", float: "right" }}>
                {/* <IconButton
                  // onClick={() => toggleDrawer("right", true, o, "consultar")}
                  style={{
                    padding: 6,
                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="eye"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton> */}
                {filesLength > 0 && (
                  <IconButton
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                    onClick={() => {
                      Instance()
                        .get(`${BASE_URL}/construcoes/obras/${o}/download`, {
                          responseType: "blob",
                        })
                        .then(({ data }) => {
                          const downloadUrl = window.URL.createObjectURL(
                            new Blob([data])
                          );

                          const link = document.createElement("a");

                          link.href = downloadUrl;

                          link.setAttribute(
                            "download",
                            `obra_${o}_orcamentos.zip`
                          ); //any other extension

                          document.body.appendChild(link);

                          link.click();
                          link.remove();
                          // dispatch(ShowNotification(message));
                        })
                        .catch((err) => {
                          console.log(err.response);
                          if (err.response) {
                            if (err.response === 403) {
                              // dispatch(ShowNotification(errorMessage));
                            }
                          }
                        });
                    }}
                  >
                    <FeatherIcon
                      icon="download"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => toggleDrawer("right", true, o, "edit")}
                  style={{
                    padding: 6,

                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              </div>
            );
          } else if (userConsultar === true) {
            return (
              <IconButton
                onClick={() => toggleDrawer("right", true, o, "consultar")}
                style={{
                  padding: 6,

                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            );
          } else if (userEditar === true && userConsultar === true) {
            return (
              <div style={{ display: "flex" }}>
                <IconButton
                  onClick={() => toggleDrawer("right", true, o, "edit")}
                  style={{
                    padding: 6,

                    color: theme.palette.secondary.main,
                  }}
                >
                  <FeatherIcon
                    icon="edit"
                    size={20}
                    style={{ strokeWidth: "1.5" }}
                  />
                </IconButton>
              </div>
            );
          }
        },
      },
    },
    {
      name: "numOrcamentos",
      label: "numOrcamentos",
      options: {
        display: false,
      },
    },
  ];

  // ############################################################## //
  // ###################  MUI Datatable options  ################## //
  // ############################################################## //

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const fileName = "obras";

      const columnsToSkip = [12, 13, 14, 15, 16, 17, 18, 19]; // Specify the indices of the columns to skip
      const dateFormatColumns = [2, 4, 5, 7, 8]; // Specify the indices of the columns to format as dates
      const moedaColumns = [9, 10, 11];

      // Filter columns to include only non-skipped columns
      const filteredColumns = columns.filter(
        (column, idx) => !columnsToSkip.includes(idx)
      );

      // Build the json, including date format for specific columns
      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          if (!columnsToSkip.includes(idx)) {
            console.log(filteredColumns);
            const column = filteredColumns[idx];
            const { label } = column;

            if (dateFormatColumns.includes(idx)) {
              if (v) {
                temp[label] = formatDate(v);
              } else {
                temp[label] = "-";
              }
            } else if (moedaColumns.includes(idx)) {
              if (v) {
                const formatEuro = (value) => {
                  return new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(value);
                };

                // Example usage
                temp[label] = formatEuro(v);
              } else {
                temp[label] = "-";
              }
            } else {
              temp[label] = v;
            }
          }
        });
        result.push(temp);
        return result;
      }, []);

      const ws = utils.json_to_sheet(json);

      // Calculate column widths based on content and header
      const columnWidths = filteredColumns.map((column) => {
        const { label } = column;
        const columnName = utils.encode_col(label.charCodeAt(0) - 65); // Convert label to column name (A, B, C, ...)
        const columnData = ws[`!${columnName}1`] || { v: "" };
        const cellTextLength = String(columnData.v).length;
        const maxLength = json.reduce((max, row) => {
          const cellValue = row[label] || "";
          const cellLength = String(cellValue).length;
          return Math.max(max, cellLength);
        }, cellTextLength);
        const headerLength = label.length;
        return { wch: Math.max(maxLength + 1, headerLength) };
      });

      // Apply column widths to the worksheet
      columnWidths.forEach((width, index) => {
        ws[`!cols`] = ws[`!cols`] || [];
        ws[`!cols`][index] = width;
      });

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);

      // Cancel default CSV download from the table
      return false;
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style:
    //       rowIndex % 2
    //         ? { backgroundColor: 'transparent' }
    //         : { backgroundColor: '#F3F5F8' },
    //   };
    // },
    textLabels: {
      body: {
        noMatch: "Sem orçamentos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },

      // noMatch: obrasState ? (
      //   <Spinner />
      // ) : (
      //   'Sorry, there is no matching data to display'
      // ),
    },
    customToolbar: () => {
      return <CustomToolbar obrasState={obrasState} />;
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <SideDrawer
          state={state}
          toggleDrawer={toggleDrawer}
          obras={obrasState}
          fetchObras={fetchObras}
          drawerState={drawerState}
          estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
          estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
          aeroportoPreSelectedId={aeroportoPreSelectedId}
          controloPreSelectedId={controloPreSelectedId}
          diretorObraPreSelectedId={diretorObraPreSelectedId}
          ficheirosState={ficheirosState}
        />

        <div
          style={{
            padding:
              dimensions && dimensions.width < 800
                ? "0px 10px 0px 20px"
                : "0px 40px 0px 50px",
          }}
        >
          <Paper className={classes.paperFilters} elevation={5}>
            {/* <FiltersObras
                  estadoProjeto={estadoProjeto}
                  estadoSubProjeto={estadoSubProjeto}
                  fetchObras={fetchObras}
                  estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
                  estadosSubProjetoPreSelectedId={
                    estadosSubProjetoPreSelectedId
                  }
                  // setEstadosProjetoPreSelectedId={
                  //   setEstadosProjetoPreSelectedId
                  // }
                  // setEstadosSubProjetoPreSelectedId={
                  //   setEstadosSubProjetoPreSelectedId
                  // }
                  // AEROPORTOS
                  aeroportos={aeroportos}
                  aeroportoPreSelectedId={aeroportoPreSelectedId}
                  // setAeroportoPreSelectedId={setAeroportoPreSelectedId}
                  // CONTROLO
                  controlo={controlo}
                  controloPreSelectedId={controloPreSelectedId}
                  // setControloPreSelectedId={setControloPreSelectedId}
                /> */}
            <FiltersObrasCheck
              user={user}
              fetchObras={fetchObras}
              // Estado Projeto
              estadoProjeto={estadoProjeto}
              estadosProjetoPreSelectedId={estadosProjetoPreSelectedId}
              // Estado SubProjeto
              estadoSubProjeto={estadoSubProjeto}
              estadosSubProjetoPreSelectedId={estadosSubProjetoPreSelectedId}
              // AEROPORTOS
              aeroportos={aeroportos}
              aeroportoPreSelectedId={aeroportoPreSelectedId}
              // CONTROLO
              controlo={controlo}
              controloPreSelectedId={controloPreSelectedId}
              // DIRETOR OBRA
              diretorObra={diretorObra}
              diretorObraPreSelectedId={diretorObraPreSelectedId}
            />
          </Paper>

          <MUIDataTable
            title={
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="text-underline"
              >
                Lista de obras
              </span>
            }
            data={obrasState || []}
            // data={[]}
            columns={columns}
            options={options}
          />
        </div>
        <div style={{ margin: "20px 40px" }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default Obras;
