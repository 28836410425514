/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Tab,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import moment from "moment";
import EditPlusAdesao from "../../../../../utils/redux/actions/plus/EditPlusAdesaoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EmitirContratoById from "../../../../../utils/redux/actions/plus/EmitirContratoByIdAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import GetAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoById";
import DrawerHistorico from "./drawerHistorico";
import AddPlusAdesao from "../../../../../utils/redux/actions/plus/AddPlusAdesaoAction";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "auto",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
  tab: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "0px",
    textTransform: "capitalize",
  },
});

const FormAdd = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  fetchAdesoes,
  adesao,
  setAdesao,
  ciclos,
  escaloes,
  potencias,
  tiposTarifas,
  tiposDonos,
  tiposEdificios,
  tiposDocs,
  planos,
  status,
  responsaveis,
  parceiros,
  servico,
  estado,
  date,
  servicos,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [adesaoData, setAdesaoData] = useState();
  const [adesaoLastUpdateData, setAdesaoLastUpdateData] = useState();
  const [adesaoValiDoc, setAdesaoValiDoc] = useState(null);
  const [dataCesContrato, setDataCesContrato] = useState(null);

  const documentos = useSelector((state) => state.plus.adesaoFiles);

  // ############################################################## //
  // ####################  Adesão Selecionada  #################### //
  // ############################################################## //

  // const prefContactos = useSelector((state) => state.plus.contactos);

  const [prefContacto, setPrefContacto] = useState({ id: 0, name: "" });
  const [envioFaturacao, setEnvioFaturacao] = useState({ id: 0, name: "" });
  const [modPagamento, setModPagamento] = useState({ id: 0, name: "" });
  const [tarifa, setTarifa] = useState({ id: 0, name: "" });
  const [ciclo, setCiclo] = useState({ id: 0, name: "" });
  const [escalao, setEscalao] = useState({ id: 0, name: "" });
  const [potencia, setPotencia] = useState({ id: 0, name: "" });
  const [tarifPlan, setTarifPlan] = useState({ id: 0, name: "" });
  const [estadoAde, setEstadoAde] = useState({ id: 0, name: "" });
  const [responsavel, setResponsavel] = useState({ id: 0, name: "" });
  const [parceiro, setParceiro] = useState({ id: 0, name: "" });
  const [tipoDono, setTipoDono] = useState({ id: 0, name: "" });
  const [tipoPredio, setTipoPredio] = useState({ id: 0, name: "" });
  const [tipoDoc, setTipoDoc] = useState({ id: 0, name: "" });
  const [prioCliente, setPrioCliente] = useState({ id: 0, name: "" });
  const [tipoServico, setTipoServico] = useState({ id: 0, name: "" });
  const [tipoCliente, setTipoCliente] = useState({ id: 0, name: "" });

  const [titCon, setTitCont] = useState({ id: 0, name: "" });
  const [newCon, setNewCon] = useState({ id: 0, name: "" });
  const [fornEne, setFornEne] = useState({ id: 0, name: "" });
  const [inspGas, setInspGas] = useState({ id: 0, name: "" });
  const [fornGas, setFornGas] = useState({ id: 0, name: "" });
  const [multiponto, setMultiponto] = useState({ id: 0, name: "" });
  const [empNomeInd, setEmpNomeInd] = useState({ id: 0, name: "" });
  const [taxMorada, setTaxMorada] = useState({ id: 0, name: "" });
  const [mailMorada, setMailMorada] = useState({ id: 0, name: "" });

  const prefContactos = [
    { id: 1, name: "Email" },
    { id: 2, name: "Telemóvel" },
    { id: 3, name: "Correio" },
  ];

  const envioFaturaOptions = [
    { id: 1, name: "Correio" },
    { id: 2, name: "Fatura Eletrónica" },
  ];

  const modPagamentosOptions = [
    { id: 1, name: "Multibanco" },
    { id: 2, name: "Débito Direto" },
  ];

  const prioridadeOptions = [
    { id: 1, name: "Cliente Prioritário" },
    { id: 2, name: "Com necessidades" },
  ];

  const YesNo = [
    { id: 0, name: "Não" },
    { id: 1, name: "Sim" },
  ];

  const optionsClientes = [
    { id: 1, name: "Particular" },
    { id: 2, name: "Empresa" },
  ];

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const renderPrefContacto = (email_flag, phone_flag, mail_flag) => {
    if (email_flag == "1") {
      setPrefContacto({ id: 1, name: "Email" });
    } else if (phone_flag == "1") {
      setPrefContacto({ id: 2, name: "Telemóvel" });
    } else if (mail_flag == "1") {
      setPrefContacto({ id: 3, name: "Correio" });
    }
    console.log(prefContacto);
  };

  const renderEnvioFatura = (mail_flag, bill_flag) => {
    if (mail_flag == "1") {
      setEnvioFaturacao({ id: 1, name: "Correio" });
    } else if (bill_flag == "1") {
      setEnvioFaturacao({ id: 2, name: "Fatura Eletrónica" });
    }
  };

  const renderModalidadePagamento = (pay_atm_flag, pay_bill_flag) => {
    if (pay_atm_flag == "1") {
      setModPagamento({ id: 1, name: "Multibanco" });
    } else if (pay_bill_flag == "1") {
      setModPagamento({ id: 2, name: "Débito Direto" });
    }
  };

  const renderPrioridade = (client_priority, client_needs) => {
    if (client_priority == "1") {
      setPrioCliente({ id: 1, name: "Cliente Prioritário" });
    } else if (client_needs == "1") {
      setPrioCliente({ id: 2, name: "Com necessidades" });
    }
  };

  const adesaoSelecionada = useSelector(
    (state) => state && state.plus && state.plus.adesao
  );

  const [personalInfo, setPersonalInfo] = useState({
    nome: "",
    apelido: "",
    telefone: "",
    telemovel: "",
    email: "",
    email_fatura: "",
  });

  const [docsInfo, setDocsInfo] = useState({
    nif: "",
    numero_documento: "",
    certidao_permanente: "",
    swift: "",
    iban: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
  });

  const [addressTaxInfo, setAddressTaxInfo] = useState({
    morada_tax: "",
    cod_postal_tax: "",
    localidade_tax: "",
    concelho_tax: "",
    distrito_tax: "",
  });

  const [addressMailInfo, setAddressMailInfo] = useState({
    morada_mail: "",
    cod_postal_mail: "",
    localidade_mail: "",
    concelho_mail: "",
    distrito_mail: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    nome_empresa: "",
    nipc: "",
    cae: "",
    rep_legal: "",
  });

  const [otherInfo, setOtherInfo] = useState({
    cui: "",
    cpe: "",
    andar: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
    observacoesPlus: "",
  });

  const [upacInfo, setUpacInfo] = useState({
    n_registo: "",
    potencia_resp: "",
    cpe_consumo: "",
    cpe_producao: "",
  });

  const [upacAddressInfo, setupacAddressInfo] = useState({
    morada_upac: "",
    cod_postal_upac: "",
    localidade_upac: "",
    concelho_upac: "",
    distrito_upac: "",
  });

  // ############################################################## //
  // ######################  Filtered arrays ###################### //
  // ############################################################## //

  const filteredTarifas = tiposTarifas.filter((t) => t.plan == tarifPlan.id);
  const filteredPotencias = potencias.filter((p) => p.type == tarifa.id);
  const filteredCiclos = ciclos.filter((c) => c.type == tarifa.id);
  const filteredEscaloes = escaloes.filter((e) => e.plan == tarifPlan.id);
  const filteredStatus = status.filter((s) => s.servId == tipoServico.id);

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleAdesaoData = (date) => {
    setAdesaoData(date);
  };

  const handleAdesaoValiDoc = (date) => {
    setAdesaoValiDoc(date);
  };

  const handleDataCessacaoContrato = (date) => {
    setDataCesContrato(date);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocsInfoChange = (e) => {
    const { name, value } = e.target;
    setDocsInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressTaxInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressTaxInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressMailInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressMailInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtherInfoChange = (e) => {
    const { name, value } = e.target;
    setOtherInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacInfoChange = (e) => {
    const { name, value } = e.target;
    setUpacInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setupacAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        clientTypeId: tipoCliente.id,
        serviceTypeId: tipoServico.id,
        statusId: estadoAde.id,
        responsableId: responsavel.id,
        partnerId: parceiro.id,
        partnerNome: parceiro.name,
        nome: personalInfo.nome,
        // apelido: personalInfo.apelido,
        telemovel: personalInfo.telemovel,
        telefone: personalInfo.telefone,
        email: personalInfo.email,
        email_fatura: personalInfo.email_fatura,
        nif: docsInfo.nif,
        tipo_documento: tipoDoc.id,
        cliente_prioritario: prioCliente.id == 1 ? 1 : 0,
        cliente_com_necessidades: prioCliente.id == 2 ? 1 : 0,
        numero_documento: docsInfo.numero_documento,
        validade_documento: adesaoValiDoc
          ? moment(adesaoValiDoc).format("YYYY-MM-DD")
          : "0000-00-00",
        certidao_permanente: docsInfo.certidao_permanente,
        // preferencia_contacto: prefContacto.id,
        email_flag: prefContacto.id == 1 ? 1 : 0,
        phone_flag: prefContacto.id == 2 ? 1 : 0,
        mail_flag: prefContacto.id == 3 ? 1 : 0,
        tax_mail_flag: envioFaturacao.id == 1 ? 1 : 0,
        tax_bill_flag: envioFaturacao.id == 2 ? 1 : 0,
        pay_atm_flag: modPagamento.id == 1 ? 1 : 0,
        pay_bill_flag: modPagamento.id == 2 ? 1 : 0,
        swift: docsInfo.swift,
        iban: docsInfo.iban,
        morada: addressInfo.morada,
        cod_postal: addressInfo.cod_postal,
        localidade: addressInfo.localidade,
        concelho: addressInfo.concelho,
        distrito: addressInfo.distrito,
        mail_morada_flag: mailMorada.id,
        morada_mail: addressMailInfo.morada_mail,
        cod_postal_mail: addressMailInfo.cod_postal_mail,
        localidade_mail: addressMailInfo.localidade_mail,
        concelho_mail: addressMailInfo.concelho_mail,
        distrito_mail: addressMailInfo.distrito_mail,
        tax_morada: taxMorada.id,
        morada_tax: addressTaxInfo.morada_tax,
        cod_postal_tax: addressTaxInfo.cod_postal_tax,
        localidade_tax: addressTaxInfo.localidade_tax,
        concelho_tax: addressTaxInfo.concelho_tax,
        distrito_tax: addressTaxInfo.distrito_tax,
        plan: tarifPlan.id,
        tipotarifa: tarifa.id,
        potencia: potencia.id,
        ciclo: ciclo.id,
        escalao: escalao.id,
        tipo_dono: tipoDono.id,
        tipo_edificio: tipoPredio.id,
        aceito_condicoes_gerais: otherInfo.aceito_condicoes_gerais,
        observacoes: otherInfo.observacoes,
        andar: otherInfo.andar,
        data_cessacao_contrato: dataCesContrato
          ? moment(dataCesContrato).format("YYYY-MM-DD")
          : null,
        data: moment(adesaoData).format("YYYY-MM-DD"),
        cpe: otherInfo.cpe,
        cui: otherInfo.cui,
        nipc: companyInfo.nipc,
        cae: companyInfo.cae,
        nome_empresa: companyInfo.nome_empresa,
        rep_legal: companyInfo.rep_legal,
        titular_contrato_anterior: titCon.id,
        nova_ligacao: newCon.id,
        fornecimento_energia: fornEne.id,
        inspecao_gas: inspGas.id,
        fornecimento_gas: fornGas.id,
        multiponto: multiponto.id,
        emp_nome_ind: empNomeInd.id,
        n_registo: upacInfo.n_registo,
        potencia_resp: upacInfo.potencia_resp,
        cpe_consumo: upacInfo.cpe_consumo,
        cpe_producao: upacInfo.cpe_producao,
        morada_upac: upacAddressInfo.morada_upac,
        cod_postal_upac: upacAddressInfo.cod_postal_upac,
        localidade_upac: upacAddressInfo.localidade_upac,
        concelho_upac: upacAddressInfo.concelho_upac,
        distrito_upac: upacAddressInfo.distrito_upac,
        observacoesPlus: otherInfo.observacoesPlus,
      },
    ];

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Adesão editada com sucesso!!",
      type: "success",
    };

    dispatch(AddPlusAdesao(ydata))
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchAdesoes(servico.id, estado.id, date); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>

      <Card
        sx={{
          mx: 5,
          px: 2,
          boxShadow: "0px 10px 15px lightgrey",
        }}
      >
        <Box
          sx={{
            my: 2,
            mx: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4
            style={{
              fontSize: "22px",
              marginBottom: "0px",
            }}
            className="text-underline"
          >
            Dados Adesão
          </h4>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              className={classes.btnFiltrar}
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Responsável Comercial
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={responsaveis || []}
                value={responsavel}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setResponsavel({ id: 0, name: "" });
                    return;
                  } else {
                    setResponsavel(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Estado
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredStatus || []}
                value={estadoAde}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEstadoAde({ id: 0, name: "" });
                    return;
                  } else {
                    setEstadoAde(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo serviço
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={servicos || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTipoServico({ id: 0, name: "" });
                    return;
                  } else {
                    setTipoServico(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo Cliente
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={optionsClientes || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTipoCliente({ id: 0, name: "" });
                    return;
                  } else {
                    setTipoCliente(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              m: 1,
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Agente Comercial
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={parceiros || []}
                value={parceiro}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setParceiro({ id: 0, name: "" });
                    return;
                  } else {
                    setParceiro(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Observações Plus
          </Typography>
          <TextField
            name="observacoesPlus"
            size="small"
            variant="outlined"
            onChange={handleOtherInfoChange}
            style={{ width: "100%" }}
            autoComplete="off"
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 3,
                style: { width: "100%" },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 1,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Dados Pessoais
          </Typography>
        </Box>
        {tipoCliente.id == 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nome
              </Typography>
              <TextField
                name="nome"
                size="small"
                variant="outlined"
                onChange={handlePersonalInfoChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
            {/* <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Apelido
                </Typography>
                <TextField
                  name="apelido"
                  size="small"
                  variant="outlined"
                  value={personalInfo.apelido}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box> */}
          </Box>
        )}
        {tipoCliente.id == 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nome da empresa
                </Typography>
                <TextField
                  name="nome_empresa"
                  size="small"
                  variant="outlined"
                  onChange={handleCompanyInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Certidão Permanente
                </Typography>
                <TextField
                  name="certidao_permanente"
                  size="small"
                  variant="outlined"
                  onChange={handleDocsInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  NIPC
                </Typography>
                <TextField
                  name="nipc"
                  size="small"
                  variant="outlined"
                  onChange={handleCompanyInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  CAE
                </Typography>
                <TextField
                  name="cae"
                  size="small"
                  variant="outlined"
                  onChange={handleCompanyInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Representante Legal
              </Typography>
              <TextField
                name="rep_legal"
                size="small"
                variant="outlined"
                onChange={handleCompanyInfoChange}
                style={{ width: "100%" }}
                autoComplete="off"
              />
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telefone
            </Typography>
            <TextField
              name="telefone"
              size="small"
              variant="outlined"
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Telemóvel
            </Typography>
            <TextField
              name="telemovel"
              size="small"
              variant="outlined"
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tipo Documento
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={tiposDocs || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTipoDoc({ id: 0, name: "" });
                  return;
                } else {
                  setTipoDoc(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Cliente Prioritário / Cliente com necessidades
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={prioridadeOptions || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setPrioCliente({ id: 0, name: "" });
                  return;
                } else {
                  setPrioCliente(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Nº Doc. Identificação
            </Typography>
            <TextField
              name="numero_documento"
              size="small"
              variant="outlined"
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "20%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptLocale}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Validade Doc. Id.
              </Typography>
              <Box sx={{ display: "flex" }}>
                <DatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={adesaoValiDoc}
                  onChange={handleAdesaoValiDoc}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "100%" }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box sx={{ m: 1, width: "25%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              NIF
            </Typography>
            <TextField
              name="nif"
              size="small"
              variant="outlined"
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email
            </Typography>
            <TextField
              name="email"
              size="small"
              variant="outlined"
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              IBAN
            </Typography>
            <TextField
              name="iban"
              size="small"
              variant="outlined"
              onChange={handleDocsInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tarifa Social
            </Typography>
            <TextField
              name="tarifaSocial"
              size="small"
              variant="outlined"
              value={adesao.tarifaSocial}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Titular Contrato Anterior
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={YesNo || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTitCont({ id: 0, name: "" });
                  return;
                } else {
                  setTitCont(value);
                  return value;
                }
              }}
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Preferência de contacto
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={prefContactos || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setPrefContacto({ id: 0, name: "" });
                  return;
                } else {
                  setPrefContacto(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Envio de Faturação
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={envioFaturaOptions || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setEnvioFaturacao({ id: 0, name: "" });
                  return;
                } else {
                  setEnvioFaturacao(value);
                  return value;
                }
              }}
            />
          </Box>

          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Email de fatura
            </Typography>
            <TextField
              name="email_fatura"
              size="small"
              variant="outlined"
              onChange={handlePersonalInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Modalidade de pagamento
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={modPagamentosOptions || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setModPagamento({ id: 0, name: "" });
                  return;
                } else {
                  setModPagamento(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {tipoServico.id == 2 && (
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              A morada fiscal é diferente do CPE
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={YesNo || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTaxMorada({ id: 0, name: "" });
                  return;
                } else {
                  setTaxMorada(value);
                  return value;
                }
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Morada
            </Typography>
            <TextField
              name="morada"
              size="small"
              variant="outlined"
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Cód-Postal
            </Typography>
            <TextField
              name="cod_postal"
              size="small"
              variant="outlined"
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Localidade
            </Typography>
            <TextField
              name="localidade"
              size="small"
              variant="outlined"
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Concelho
            </Typography>
            <TextField
              name="concelho"
              size="small"
              variant="outlined"
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Distrito
            </Typography>
            <TextField
              name="distrito"
              size="small"
              variant="outlined"
              onChange={handleAddressInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        </Box>
        {tipoServico.id == 1 && (
          <>
            <Box
              sx={{
                my: 2,
                mx: 1,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "18px",
                }}
              >
                Morada de correspondência
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Mesma morada do cliente
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={YesNo || []}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setMailMorada({ id: 0, name: "" });
                      return;
                    } else {
                      setMailMorada(value);
                      return value;
                    }
                  }}
                />
              </Box>
              {mailMorada.id == 0 && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Morada
                  </Typography>
                  <TextField
                    name="morada_mail"
                    size="small"
                    variant="outlined"
                    value={addressMailInfo.morada_mail}
                    onChange={handleAddressMailInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              )}
            </Box>
            {mailMorada.id == 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Cód-Postal
                    </Typography>
                    <TextField
                      name="cod_postal_mail"
                      size="small"
                      variant="outlined"
                      value={addressMailInfo.cod_postal_mail}
                      onChange={handleAddressMailInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Localidade
                    </Typography>
                    <TextField
                      name="localidade_mail"
                      size="small"
                      variant="outlined"
                      value={addressMailInfo.localidade_mail}
                      onChange={handleAddressMailInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Concelho
                    </Typography>
                    <TextField
                      name="concelho_mail"
                      size="small"
                      variant="outlined"
                      value={addressMailInfo.concelho_mail}
                      onChange={handleAddressMailInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Distrito
                    </Typography>
                    <TextField
                      name="distrito_mail"
                      size="small"
                      variant="outlined"
                      value={addressMailInfo.distrito_mail}
                      onChange={handleAddressMailInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {tipoServico.id == 1 && (
          <>
            <Box
              sx={{
                my: 2,
                mx: 1,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "18px",
                }}
              >
                Morada de fornecimento
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Mesma morada do cliente
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={YesNo || []}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTaxMorada({ id: 0, name: "" });
                      return;
                    } else {
                      setTaxMorada(value);
                      return value;
                    }
                  }}
                />
              </Box>
              {taxMorada.id == 0 && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Morada
                  </Typography>
                  <TextField
                    name="morada_tax"
                    size="small"
                    variant="outlined"
                    value={addressTaxInfo.morada_tax}
                    onChange={handleAddressTaxInfoChange}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              )}
            </Box>
            {taxMorada.id == 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Cód-Postal
                    </Typography>
                    <TextField
                      name="cod_postal_tax"
                      size="small"
                      variant="outlined"
                      value={addressTaxInfo.cod_postal_tax}
                      onChange={handleAddressTaxInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Localidade
                    </Typography>
                    <TextField
                      name="localidade_tax"
                      size="small"
                      variant="outlined"
                      value={addressTaxInfo.localidade_tax}
                      onChange={handleAddressTaxInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Concelho
                    </Typography>
                    <TextField
                      name="concelho_tax"
                      size="small"
                      variant="outlined"
                      value={addressTaxInfo.concelho_tax}
                      onChange={handleAddressTaxInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      Distrito
                    </Typography>
                    <TextField
                      name="distrito_tax"
                      size="small"
                      variant="outlined"
                      value={addressTaxInfo.distrito_tax}
                      onChange={handleAddressTaxInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}

        {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              CPE
            </Typography>
            <TextField
              name="cpe"
              size="small"
              variant="outlined"
              onChange={handleOtherInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        )}
        {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              CUI
            </Typography>
            <TextField
              name="cui"
              size="small"
              variant="outlined"
              onChange={handleOtherInfoChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
        )}

        {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Nova Ligação
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setNewCon({ id: 0, name: "" });
                    return;
                  } else {
                    setNewCon(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Fornecimento de Energia
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setFornEne({ id: 0, name: "" });
                    return;
                  } else {
                    setFornEne(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Inspeção Gás
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setInspGas({ id: 0, name: "" });
                    return;
                  } else {
                    setInspGas(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Fornecimento Gás
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setFornGas({ id: 0, name: "" });
                    return;
                  } else {
                    setFornGas(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Multiponto
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={YesNo || []}
              value={multiponto}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setMultiponto({ id: 0, name: "" });
                  return;
                } else {
                  setMultiponto(value);
                  return value;
                }
              }}
            />
          </Box>
          {tipoCliente.id == 2 && (
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Empresário nome individual
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={YesNo || []}
                value={empNomeInd}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEmpNomeInd({ id: 0, name: "" });
                    return;
                  } else {
                    setEmpNomeInd(value);
                    return value;
                  }
                }}
              />
            </Box>
          )}
        </Box>
        {tipoServico.id == 1 && (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo Cliente(Dono)
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tiposDonos || []}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoDono({ id: 0, name: "" });
                      return;
                    } else {
                      setTipoDono(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo Prédio
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tiposEdificios || []}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoPredio({ id: 0, name: "" });
                      return;
                    } else {
                      setTipoPredio(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Fração / Andar
                </Typography>
                <TextField
                  name="andar"
                  size="small"
                  variant="outlined"
                  onChange={handleOtherInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data cessação contrato
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={dataCesContrato}
                      onChange={handleDataCessacaoContrato}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "50%" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </Box>
          </>
        )}
        {tipoServico.id == 2 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mx: 1,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Dados UPAC
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Número de registo
                </Typography>
                <TextField
                  name="n_registo"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Potência de injeção na RESP (kVA)
                </Typography>
                <TextField
                  name="potencia_resp"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  CPE Consumo
                </Typography>
                <TextField
                  name="cpe_consumo"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  CPE Produção
                </Typography>
                <TextField
                  name="cpe_producao"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Morada
                </Typography>
                <TextField
                  name="morada_upac"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cód-Postal
                </Typography>
                <TextField
                  name="cod_postal_upac"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Localidade
                </Typography>
                <TextField
                  name="localidade_upac"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Concelho
                </Typography>
                <TextField
                  name="concelho_upac"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Distrito
                </Typography>
                <TextField
                  name="distrito_upac"
                  size="small"
                  variant="outlined"
                  onChange={handleUpacAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 1,
            mt: 2,
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "20px",
            }}
          >
            Plano
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Serviço
            </Typography>
            <Autocomplete
              fullWidth
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem dados"
              options={planos || []}
              getOptionLabel={(option) => `${option.name}` || ""}
              isOptionEqualToValue={(option, value) => {
                // if (!value.id) return true;
                return value.id === option.id;
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" variant="outlined" />
              )}
              onChange={(_, value, reason) => {
                if (reason === "clear") {
                  setTarifPlan({ id: 0, name: "" });
                  return;
                } else {
                  setTarifPlan(value);
                  return value;
                }
              }}
            />
          </Box>
        </Box>
        {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Tipo de Tarifa
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredTarifas || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setTarifa({ id: 0, name: "" });
                    return;
                  } else {
                    setTarifa(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "22%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Potência
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredPotencias || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setPotencia({ id: 0, name: "" });
                    return;
                  } else {
                    setPotencia(value);
                    return value;
                  }
                }}
              />
            </Box>
            <Box sx={{ m: 1, width: "22%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Ciclo
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredCiclos || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setCiclo({ id: 0, name: "" });
                    return;
                  } else {
                    setCiclo(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* {tarifPlan.id == 5 && (
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Ciclo
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={filteredCiclos || []}
                  value={ciclo}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setCiclo(null);
                      return;
                    } else {
                      setCiclo(value);
                      return value;
                    }
                  }}
                />
              </Box>
            )} */}
            <Box sx={{ m: 1, width: "48%" }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Escalão
              </Typography>
              <Autocomplete
                fullWidth
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem dados"
                options={filteredEscaloes || []}
                getOptionLabel={(option) => `${option.name}` || ""}
                isOptionEqualToValue={(option, value) => {
                  // if (!value.id) return true;
                  return value.id === option.id;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                onChange={(_, value, reason) => {
                  if (reason === "clear") {
                    setEscalao({ id: 0, name: "" });
                    return;
                  } else {
                    setEscalao(value);
                    return value;
                  }
                }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ m: 1 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Observações
          </Typography>
          <TextField
            name="observacoes"
            size="small"
            variant="outlined"
            onChange={handleOtherInfoChange}
            style={{ width: "100%" }}
            autoComplete="off"
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 3,
                style: { width: "100%" },
              },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default FormAdd;
