/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

// import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Tab,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ptLocale from "date-fns/locale/pt";
import moment from "moment";
import EditPlusAdesao from "../../../../../utils/redux/actions/plus/EditPlusAdesaoAction";
import ShowNotification from "../../../../../utils/redux/actions/global/notifications/NotificationAction";
import EmitirContratoById from "../../../../../utils/redux/actions/plus/EmitirContratoByIdAction";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import GetAdesaoById from "../../../../../utils/redux/actions/plus/GetAdesaoById";
import DrawerHistorico from "./drawerHistorico";
import ModalFileDelete from "./modalFileDelete";
import ModalAddFile from "./modalAddFile";
import Instance from "../../../../../utils/Axios";
import BASE_URL from "../../../../../utils/BASE_URL";
import fileDownload from "js-file-download";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    borderRadius: "10px 0px 0px 10px !important",
    // background: 'red !important',
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#FAFAFA",
  },
  btnGuardar: {
    color: "white",
    width: "120px",
    height: "40px",
    // borderRadius: '15px',
    backgroundColor: "#E21450",
    border: `1px solid #E21450`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#bc1041",
      border: "1px solid #bc1041",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#bc1041",
      },
      "&:disabled": {
        backgroundColor: "#bc1041",
      },
    },
  },
  btnCloseDrawer: {
    color: "#464255",
    width: "30px",
    height: "40px",
    borderRadius: "15px",
    backgroundColor: "#f2f2f2",
    border: `1px solid #f2f2f2`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      border: "1px solid #e0e0e0",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#e0e0e0",
      },
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },

  noBorder: {
    border: "none",
  },
  btnFiltrar: {
    color: "#E21450",
    width: "auto",
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  btnTrue: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "white",
    "&:focus": {
      background: "white",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnFalse: {
    display: "inline-block",
    width: "100%",
    height: 40,
    border: "1px solid lightgrey",
    borderRadius: "5px",
    background: "lightgrey",
    "&:focus": {
      background: "lightgrey",
    },
    "&:hover": {
      backgroundColor: "lightgrey",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "lightgrey",
      },
    },
  },
  btnUpload: {
    color: "#E21450",
    // width: '125px',
    height: "40px",
    fontWeight: "bold",
    // borderRadius: '15px',
    backgroundColor: "white",
    border: `1px solid #707070`,
    display: "flex",
    alignItems: "center",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#ECECEC",
      border: "1px solid #707070",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#F7F7F7 ",
      },
    },
  },
  blurredText: {
    color: "transparent", // Make the text transparent
    textShadow: "0 0 10px #000", // Apply a text shadow to create the blur effect
    userSelect: "none", // Prevent text selection
  },
  disabledTextField: {
    opacity: 0.7,
    pointerEvents: "none",
    userSelect: "none", // Adjust opacity to indicate the disabled state
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "transparent",
    },
  },
  textField: {
    userSelect: "none",
  },
  tab: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "0px",
    textTransform: "capitalize",
  },
});

const Form = ({
  toggleDrawer,
  drawerWidth,
  dimensions,
  fetchAdesoes,
  adesao,
  setAdesao,
  ciclos,
  escaloes,
  potencias,
  tiposTarifas,
  tiposDonos,
  tiposEdificios,
  tiposDocs,
  tiposDocsAdesao,
  tiposDocsCompExc,
  planos,
  status,
  responsaveis,
  parceiros,
  servico,
  estado,
  date,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [adesaoData, setAdesaoData] = useState();
  const [adesaoLastUpdateData, setAdesaoLastUpdateData] = useState();
  const [adesaoValiDoc, setAdesaoValiDoc] = useState();
  const [dataCesContrato, setDataCesContrato] = useState();

  const user = useSelector((state) => state.user);

  const documentos = useSelector((state) => state.plus.adesaoFiles);

  const [adesaoHistorico, setAdesaoHistorico] = useState({
    nome: "",
    // apelido: "",
    telemovel: "",
    email: "",
    nif: "",
    numero_documento: "",
    validade_documento: "",
    certidao_permanente: "",
    tarifaSocial: "",
    titular_contrato_anterior: "",
    preferencia_contacto: "",
    iban: "",
    morada: "",
    cod_postal: "",
    localidade: "",
    nova_ligacao: "",
    fornecimento_energia: "",
    tipotarifa: "",
    potencia: "",
    ciclo: "",
    escalao: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
    data: "",
    nipc: "",
    cae: "",
    cpe: "",
    cui: "",
    nome_empresa: "",
    rep_legal: "",
    inspecao_gas: "",
    fornecimento_gas: "",
    fatura_gas: "",
  });

  const resetAdesaoHistorico = () => {
    setAdesao({
      id: "",
      nome: "",
      // apelido: "",
      telemovel: "",
      email: "",
      nif: "",
      numero_documento: "",
      validade_documento: "",
      certidao_permanente: "",
      iban: "",
      titular_contrato_anterior: "",
      preferencia_contacto: "",
      morada: "",
      cod_postal: "",
      localidade: "",
      concelho: "",
      distrito: "",
      nome_empresa: "",
      nipc: "",
      cae: "",
      rep_legal: "",
      cui: "",
      cpe: "",
      aceito_condicoes_gerais: "",
      observacoes: "",
      n_registo: "",
      potencia_resp: "",
      cpe_consumo: "",
      cpe_producao: "",
      morada_upac: "",
      cod_postal_upac: "",
      localidade_upac: "",
      concelho_upac: "",
      distrito_upac: "",
    });
  };

  // ############################################################## //
  // ####################  Modal File Delete  ##################### //
  // ############################################################## //
  const [openFileDelete, setOpenFileDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(null);

  const handleOpenFileDelete = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenFileDelete(true);
    setFileId(id);
  };

  const handleCloseFileDelete = (e) => {
    e.preventDefault();
    setOpenFileDelete(false);
  };

  // ############################################################## //
  // ######################  Modal Add Docs  ###################### //
  // ############################################################## //
  const [openAddDocs, setOpenAddDocs] = useState(false);

  const handleOpenAddDoc = (e) => {
    e.preventDefault();

    setLoading(false);

    setOpenAddDocs(true);
  };

  const handleCloseAddDoc = (e) => {
    e.preventDefault();
    setOpenAddDocs(false);
  };

  // ############################################################## //
  // ########################  Drawer  ############################ //
  // ############################################################## //

  const fetchAdesaoById = (id) => {
    dispatch(GetAdesaoById(id));
  };

  const [historicoState, setHistoricoState] = useState({
    right: false,
  });
  const [drawerStateHistorico, setDrawerStateHistorico] = useState(null);
  const toggleDrawerHistorico = (anchor, open, id) => {
    console.log(id);
    if (open == false) {
      setHistoricoState({ ...historicoState, [anchor]: open });
      // setCredenciacaoId(null);
      resetAdesaoHistorico();
    }
    if (open == true) {
      setHistoricoState({ ...historicoState, [anchor]: open });
      // setCredenciacaoId(id);
      fetchAdesaoById(id);
    }
  };

  // ############################################################## //
  // ############################ Tabs  ########################### //
  // ############################################################## //

  const [tabsvalue, setTabsValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  // ############################################################## //
  // ##################  MUI Datatable columns  ################### //
  // ############################################################## //

  const historico = useSelector((state) => state.plus.adesaoHistorico);

  const columns = [
    {
      name: "id_jaf_plus_client_form_data",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "id_jaf_plus_client_form",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "jaf_plus_client_form_data_created_at",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposatrasos">
              {value == null ? (
                " - "
              ) : (
                <>{format(new Date(value), "dd/MM/yyyy hh:mm")}</>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "userName",
      label: "Alterado por",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { width: "5%" },
          align: "center",
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div style={{ display: "flex", float: "right" }}>
              <IconButton
                onClick={() => {
                  setDrawerStateHistorico("watch");
                  toggleDrawerHistorico("right", true, id);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="eye"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
              {/* <IconButton
                onClick={(e) => {
                  handleOpen(e, id);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="trash-2"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton> */}
            </div>
          );
        },
      },
    },
  ];

  // ############################################################## //
  // ##################  MUI Datatable Options  ################### //
  // ############################################################## //
  const location = useLocation();
  const [responsive] = useState("standard");
  const [tableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight] = useState("");

  const searchText =
    location.state && location.state.funcionarioNome
      ? location.state.funcionarioNome
      : null;

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    searchText: searchText,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: "none",
    viewColumns: false,
    elevation: 5,
    pagination: true,
    print: false,
    download: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 150, 200, 250, 300],
    textLabels: {
      body: {
        noMatch: "Sem Registos encontrados",
        // toolTip: 'Dia',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: "Pesquisar",
      },
      pagination: {
        next: "Proxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Mostrar:",
        displayRows: "de",
      },
    },
  };

  // ############################################################## //
  // ####################  Adesão Selecionada  #################### //
  // ############################################################## //

  // const prefContactos = useSelector((state) => state.plus.contactos);

  const [prefContacto, setPrefContacto] = useState({ id: 0, name: "" });
  const [envioFaturacao, setEnvioFaturacao] = useState({ id: 0, name: "" });
  const [modPagamento, setModPagamento] = useState({ id: 0, name: "" });
  const [tarifa, setTarifa] = useState({ id: 0, name: "" });
  const [ciclo, setCiclo] = useState({ id: 0, name: "" });
  const [escalao, setEscalao] = useState({ id: 0, name: "" });
  const [potencia, setPotencia] = useState({ id: 0, name: "" });
  const [tarifPlan, setTarifPlan] = useState({ id: 0, name: "" });
  const [estadoAde, setEstadoAde] = useState({ id: 0, name: "" });
  const [responsavel, setResponsavel] = useState({ id: 0, name: "" });
  const [parceiro, setParceiro] = useState({ id: 0, name: "" });
  const [tipoDono, setTipoDono] = useState({ id: 0, name: "" });
  const [tipoPredio, setTipoPredio] = useState({ id: 0, name: "" });
  const [tipoDoc, setTipoDoc] = useState({ id: 0, name: "" });
  const [prioCliente, setPrioCliente] = useState({ id: 0, name: "" });

  const [titCon, setTitCont] = useState({ id: 0, name: "" });
  const [newCon, setNewCon] = useState({ id: 0, name: "" });
  const [fornEne, setFornEne] = useState({ id: 0, name: "" });
  const [inspGas, setInspGas] = useState({ id: 0, name: "" });
  const [fornGas, setFornGas] = useState({ id: 0, name: "" });
  const [multiponto, setMultiponto] = useState({ id: 0, name: "" });
  const [empNomeInd, setEmpNomeInd] = useState({ id: 0, name: "" });
  const [taxMorada, setTaxMorada] = useState({ id: 0, name: "" });
  const [mailMorada, setMailMorada] = useState({ id: 0, name: "" });

  const prefContactos = [
    { id: 1, name: "Email" },
    { id: 2, name: "Telemóvel" },
    { id: 3, name: "Correio" },
  ];

  const envioFaturaOptions = [
    { id: 1, name: "Correio" },
    { id: 2, name: "Fatura Eletrónica" },
  ];

  const modPagamentosOptions = [
    { id: 1, name: "Multibanco" },
    { id: 2, name: "Débito Direto" },
  ];

  const prioridadeOptions = [
    { id: 1, name: "Cliente Prioritário" },
    { id: 2, name: "Com necessidades" },
  ];

  const YesNo = [
    { id: 0, name: "Não" },
    { id: 1, name: "Sim" },
  ];

  const renderTarifa = (value) => {
    switch (value) {
      case "simples":
        return "Simples";
      case "bi_horario":
        return "Bi-Horário";
      case "tri_horario":
        return "Tri-Horário";
      default:
        break;
    }
  };

  const renderEscalao = (value) => {
    switch (value) {
      case "escalao1":
        return "Escalão 1";
      case "escalao2":
        return "Escalão 2";
      case "escalao3":
        return "Escalão 3";
      default:
        break;
    }
  };

  const renderPrefContacto = (email_flag, phone_flag, mail_flag) => {
    if (email_flag == "1") {
      setPrefContacto({ id: 1, name: "Email" });
    } else if (phone_flag == "1") {
      setPrefContacto({ id: 2, name: "Telemóvel" });
    } else if (mail_flag == "1") {
      setPrefContacto({ id: 3, name: "Correio" });
    }
    console.log(prefContacto);
  };

  const renderEnvioFatura = (mail_flag, bill_flag) => {
    if (mail_flag == "1") {
      setEnvioFaturacao({ id: 1, name: "Correio" });
    } else if (bill_flag == "1") {
      setEnvioFaturacao({ id: 2, name: "Fatura Eletrónica" });
    }
  };

  const renderModalidadePagamento = (pay_atm_flag, pay_bill_flag) => {
    if (pay_atm_flag == "1") {
      setModPagamento({ id: 1, name: "Multibanco" });
    } else if (pay_bill_flag == "1") {
      setModPagamento({ id: 2, name: "Débito Direto" });
    } else {
      setModPagamento({ id: 0, name: "" });
    }
  };

  const renderPrioridade = (client_priority, client_needs) => {
    if (client_priority == "1") {
      setPrioCliente({ id: 1, name: "Cliente Prioritário" });
    } else if (client_needs == "1") {
      setPrioCliente({ id: 2, name: "Com necessidades" });
    } else {
      setPrioCliente({ id: 0, name: "" });
    }
  };

  const adesaoSelecionada = useSelector(
    (state) => state && state.plus && state.plus.adesao
  );

  const [personalInfo, setPersonalInfo] = useState({
    nome: "",
    apelido: "",
    telefone: "",
    telemovel: "",
    email: "",
    email_fatura: "",
  });

  const [docsInfo, setDocsInfo] = useState({
    nif: "",
    numero_documento: "",
    certidao_permanente: "",
    swift: "",
    iban: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    morada: "",
    cod_postal: "",
    localidade: "",
    concelho: "",
    distrito: "",
  });

  const [addressTaxInfo, setAddressTaxInfo] = useState({
    morada_tax: "",
    cod_postal_tax: "",
    localidade_tax: "",
    concelho_tax: "",
    distrito_tax: "",
  });

  const [addressMailInfo, setAddressMailInfo] = useState({
    morada_mail: "",
    cod_postal_mail: "",
    localidade_mail: "",
    concelho_mail: "",
    distrito_mail: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    nome_empresa: "",
    nipc: "",
    cae: "",
    rep_legal: "",
  });

  const [otherInfo, setOtherInfo] = useState({
    cui: "",
    cpe: "",
    andar: "",
    aceito_condicoes_gerais: "",
    observacoes: "",
    observacoesPlus: "",
  });

  const [upacInfo, setUpacInfo] = useState({
    n_registo: "",
    potencia_resp: "",
    cpe_consumo: "",
    cpe_producao: "",
  });

  const [upacAddressInfo, setupacAddressInfo] = useState({
    morada_upac: "",
    cod_postal_upac: "",
    localidade_upac: "",
    concelho_upac: "",
    distrito_upac: "",
  });

  useEffect(() => {
    if (adesaoSelecionada) {
      setPersonalInfo({
        nome: adesaoSelecionada.jaf_plus_client_form_data_name,
        telemovel: adesaoSelecionada.jaf_plus_client_form_data_contact_mobile,
        telefone: adesaoSelecionada.jaf_plus_client_form_data_contact_landline,
        email: adesaoSelecionada.jaf_plus_client_form_data_email,
        email_fatura: adesaoSelecionada.jaf_plus_client_form_data_email,
      });
      setDocsInfo({
        nif: adesaoSelecionada.jaf_plus_client_form_data_nif,
        numero_documento: adesaoSelecionada.jaf_plus_client_form_data_cc,
        certidao_permanente:
          adesaoSelecionada.jaf_plus_client_form_data_p_certificate_code,
        swift: adesaoSelecionada.jaf_plus_client_form_data_swift,
        iban: adesaoSelecionada.jaf_plus_client_form_data_iban,
      });
      setAddressInfo({
        morada: adesaoSelecionada.jaf_plus_client_form_data_address,
        cod_postal: adesaoSelecionada.jaf_plus_client_form_data_postal_code,
        localidade: adesaoSelecionada.jaf_plus_client_form_data_location,
        concelho: adesaoSelecionada.jaf_plus_client_form_data_county,
        distrito: adesaoSelecionada.jaf_plus_client_form_data_district,
      });
      setAddressMailInfo({
        morada_mail: adesaoSelecionada.jaf_plus_client_form_data_address_mail,
        cod_postal_mail:
          adesaoSelecionada.jaf_plus_client_form_data_postal_code_mail,
        localidade_mail:
          adesaoSelecionada.jaf_plus_client_form_data_location_mail,
        concelho_mail: adesaoSelecionada.jaf_plus_client_form_data_county_mail,
        distrito_mail:
          adesaoSelecionada.jaf_plus_client_form_data_district_mail,
      });
      setAddressTaxInfo({
        morada_tax: adesaoSelecionada.jaf_plus_client_form_data_address_tax,
        cod_postal_tax:
          adesaoSelecionada.jaf_plus_client_form_data_postal_code_tax,
        localidade_tax:
          adesaoSelecionada.jaf_plus_client_form_data_location_tax,
        concelho_tax: adesaoSelecionada.jaf_plus_client_form_data_county_tax,
        distrito_tax: adesaoSelecionada.jaf_plus_client_form_data_district_tax,
      });
      setCompanyInfo({
        nipc: adesaoSelecionada.jaf_plus_client_form_data_nipc,
        cae: adesaoSelecionada.jaf_plus_client_form_data_cae,
        nome_empresa: adesaoSelecionada.jaf_plus_client_form_data_company_name,
        rep_legal: adesaoSelecionada.jaf_plus_client_form_data_legal_name,
      });
      setOtherInfo({
        aceito_condicoes_gerais:
          adesaoSelecionada.jaf_plus_client_form_data_terms_conditions_flag,
        observacoes: adesaoSelecionada.jaf_plus_client_form_data_obs,
        observacoesPlus: adesaoSelecionada.obsPlus,
        cpe: adesaoSelecionada.jaf_plus_client_form_data_cpe,
        cui: adesaoSelecionada.jaf_plus_client_form_data_cui,
        andar: adesaoSelecionada.jaf_plus_client_form_data_floor,
      });
      setUpacInfo({
        n_registo: adesaoSelecionada.jaf_plus_upac_regist_number,
        potencia_resp: adesaoSelecionada.jaf_plus_upac_power_resp,
        cpe_consumo: adesaoSelecionada.jaf_plus_upac_cep_consumption,
        cpe_producao: adesaoSelecionada.jaf_plus_upac_cep_production,
      });
      setupacAddressInfo({
        morada_upac: adesaoSelecionada.jaf_plus_upac_address,
        cod_postal_upac: adesaoSelecionada.jaf_plus_upac_postal_code,
        localidade_upac: adesaoSelecionada.jaf_plus_upac_location,
        concelho_upac: adesaoSelecionada.jaf_plus_upac_county,
        distrito_upac: adesaoSelecionada.jaf_plus_upac_district,
      });
      setTaxMorada({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_tax_address_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_tax_address_flag == "0"
            ? "Não"
            : "Sim",
      });
      setMailMorada({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_mail_address_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_mail_address_flag == "0"
            ? "Não"
            : "Sim",
      });
      renderPrefContacto(
        adesaoSelecionada.jaf_plus_client_form_data_contact_email_pref_flag,
        adesaoSelecionada.jaf_plus_client_form_data_contact_phone_pref_flag,
        adesaoSelecionada.jaf_plus_client_form_data_contact_mail_flag
      );
      renderEnvioFatura(
        adesaoSelecionada.jaf_plus_client_form_data_tax_mail_flag,
        adesaoSelecionada.jaf_plus_client_form_data_tax_bill_flag
      );
      renderModalidadePagamento(
        adesaoSelecionada.jaf_plus_client_form_data_payment_atm_flag,
        adesaoSelecionada.jaf_plus_client_form_data_payment_bill_flag
      );
      renderPrioridade(
        adesaoSelecionada.jaf_plus_client_form_data_priority_flag,
        adesaoSelecionada.jaf_plus_client_form_data_special_flag
      );
      setAdesaoData(
        moment(adesaoSelecionada.jaf_plus_client_form_created_at).format(
          "DD-MM-YYYY HH:mm:ss"
        )
      );
      setAdesaoLastUpdateData(
        moment(
          adesaoSelecionada.jaf_plus_client_form_updated_at
            ? adesaoSelecionada.jaf_plus_client_form_updated_at
            : adesaoSelecionada.jaf_plus_client_form_created_at
        ).format("DD-MM-YYYY HH:mm:ss")
      );
      setAdesaoValiDoc(
        adesaoSelecionada.jaf_plus_client_form_data_cc_due_date == "0000-00-00"
          ? null
          : new Date(adesaoSelecionada.jaf_plus_client_form_data_cc_due_date)
      );
      setDataCesContrato(
        adesaoSelecionada.jaf_plus_client_form_data_contract_due_date == null
          ? null
          : new Date(
              adesaoSelecionada.jaf_plus_client_form_data_contract_due_date
            )
      );
      setTarifa({
        id: adesaoSelecionada.id_jaf_plus_tariff_type,
        name: adesaoSelecionada.type == null ? "N/D" : adesaoSelecionada.type,
      });
      setPotencia({
        id: adesaoSelecionada.id_jaf_plus_tariff_power,
        name: adesaoSelecionada.power == null ? "N/D" : adesaoSelecionada.power,
      });
      setCiclo({
        id: adesaoSelecionada.id_jaf_plus_tariff_cycle,
        name: adesaoSelecionada.cycle == null ? "N/D" : adesaoSelecionada.cycle,
      });
      setEscalao({
        id: adesaoSelecionada.id_jaf_plus_tariff_level,
        name: adesaoSelecionada.level == null ? "N/D" : adesaoSelecionada.level,
      });
      setTarifPlan({
        id: adesaoSelecionada.id_jaf_plus_tariff_plan,
        name: adesaoSelecionada.plan == null ? "N/D" : adesaoSelecionada.plan,
      });
      setTipoDono({
        id: adesaoSelecionada.id_jaf_plus_client_owner_type,
        name:
          adesaoSelecionada.tipo_dono == null
            ? "N/D"
            : adesaoSelecionada.tipo_dono,
      });
      setTipoPredio({
        id: adesaoSelecionada.id_jaf_plus_property_type,
        name:
          adesaoSelecionada.tipo_propriedade == null
            ? "N/D"
            : adesaoSelecionada.tipo_propriedade,
      });
      setTipoDoc({
        id: adesaoSelecionada.id_jaf_plus_doc_type,
        name:
          adesaoSelecionada.tipo_documento == null
            ? "N/D"
            : adesaoSelecionada.tipo_documento,
      });
      setTitCont({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_last_owner_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_last_owner_flag == "0"
            ? "Não"
            : "Sim",
      });
      setNewCon({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_new_connection_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_new_connection_flag == "0"
            ? "Não"
            : "Sim",
      });
      setFornEne({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_power_supply_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_power_supply_flag == "0"
            ? "Não"
            : "Sim",
      });
      setInspGas({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_gas_inspection_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_gas_inspection_flag == "0"
            ? "Não"
            : "Sim",
      });
      setFornGas({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_gas_supply_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_gas_supply_flag == "0"
            ? "Não"
            : "Sim",
      });
      setMultiponto({
        id: parseInt(
          adesaoSelecionada.jaf_plus_client_form_data_multipoint_flag
        ),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_multipoint_flag == "0"
            ? "Não"
            : "Sim",
      });
      setEmpNomeInd({
        id: parseInt(adesaoSelecionada.jaf_plus_client_form_data_ib_owner_flag),
        name:
          adesaoSelecionada.jaf_plus_client_form_data_ib_owner_flag == "0"
            ? "Não"
            : "Sim",
      });
      setEstadoAde({
        id: adesaoSelecionada.estadoId,
        name: adesaoSelecionada.estadoNome,
      });
      setResponsavel({
        id: adesaoSelecionada.responsavelId
          ? adesaoSelecionada.responsavelId
          : 0,
        name: adesaoSelecionada.responsavelNome
          ? adesaoSelecionada.responsavelNome
          : "",
      });
      setParceiro({
        id: adesaoSelecionada.parceiroId ? adesaoSelecionada.parceiroId : 0,
        name: adesaoSelecionada.parceiroNome
          ? adesaoSelecionada.parceiroNome
          : "",
      });
    }
  }, [adesaoSelecionada]);

  // ############################################################## //
  // ######################  Filtered arrays ###################### //
  // ############################################################## //

  const filteredTarifas = tiposTarifas.filter((t) => t.plan == tarifPlan.id);
  const filteredPotencias = potencias.filter((p) => p.type == tarifa.id);
  const filteredCiclos = ciclos.filter((c) => c.type == tarifa.id);
  const filteredEscaloes = escaloes.filter((e) => e.plan == tarifPlan.id);
  const filteredStatus = status.filter(
    (s) => s.servId == adesaoSelecionada.id_jaf_plus_service_type
  );

  // ############################################################## //
  // ###############  Handles Change and Submit  ################## //
  // ############################################################## //

  const handleAdesaoData = (date) => {
    setAdesaoData(date);
  };

  const handleAdesaoValiDoc = (date) => {
    setAdesaoValiDoc(date);
  };

  const handleDataCessacaoContrato = (date) => {
    setDataCesContrato(date);
  };

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocsInfoChange = (e) => {
    const { name, value } = e.target;
    setDocsInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressTaxInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressTaxInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressMailInfoChange = (e) => {
    const { name, value } = e.target;
    setAddressMailInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleOtherInfoChange = (e) => {
    const { name, value } = e.target;
    setOtherInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacInfoChange = (e) => {
    const { name, value } = e.target;
    setUpacInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpacAddressInfoChange = (e) => {
    const { name, value } = e.target;
    setupacAddressInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        id_client: adesaoSelecionada.id_jaf_plus_client_form,
        clientTypeId: adesaoSelecionada.id_jaf_plus_client_type,
        serviceTypeId: adesaoSelecionada.id_jaf_plus_service_type,
        statusId: estadoAde.id,
        responsableId: responsavel.id,
        partnerId: parceiro.id,
        partnerNome: parceiro.name,
        nome: personalInfo.nome,
        // apelido: personalInfo.apelido,
        telemovel: personalInfo.telemovel,
        telefone: personalInfo.telefone,
        email: personalInfo.email,
        email_fatura: personalInfo.email_fatura,
        nif: docsInfo.nif,
        tipo_documento: tipoDoc.id,
        cliente_prioritario: prioCliente.id == 1 ? 1 : 0,
        cliente_com_necessidades: prioCliente.id == 2 ? 1 : 0,
        numero_documento: docsInfo.numero_documento,
        validade_documento: adesaoValiDoc
          ? moment(adesaoValiDoc).format("YYYY-MM-DD")
          : "0000-00-00",
        certidao_permanente: docsInfo.certidao_permanente,
        // preferencia_contacto: prefContacto.id,
        email_flag: prefContacto.id == 1 ? 1 : 0,
        phone_flag: prefContacto.id == 2 ? 1 : 0,
        mail_flag: prefContacto.id == 3 ? 1 : 0,
        tax_mail_flag: envioFaturacao.id == 1 ? 1 : 0,
        tax_bill_flag: envioFaturacao.id == 2 ? 1 : 0,
        pay_atm_flag: modPagamento.id == 1 ? 1 : 0,
        pay_bill_flag: modPagamento.id == 2 ? 1 : 0,
        swift: docsInfo.swift,
        iban: docsInfo.iban,
        morada: addressInfo.morada,
        cod_postal: addressInfo.cod_postal,
        localidade: addressInfo.localidade,
        concelho: addressInfo.concelho,
        distrito: addressInfo.distrito,
        mail_morada_flag: mailMorada.id,
        morada_mail: addressMailInfo.morada_mail,
        cod_postal_mail: addressMailInfo.cod_postal_mail,
        localidade_mail: addressMailInfo.localidade_mail,
        concelho_mail: addressMailInfo.concelho_mail,
        distrito_mail: addressMailInfo.distrito_mail,
        tax_morada: taxMorada.id,
        morada_tax: addressTaxInfo.morada_tax,
        cod_postal_tax: addressTaxInfo.cod_postal_tax,
        localidade_tax: addressTaxInfo.localidade_tax,
        concelho_tax: addressTaxInfo.concelho_tax,
        distrito_tax: addressTaxInfo.distrito_tax,
        plan: tarifPlan.id,
        tipotarifa: tarifa.id,
        potencia: potencia.id,
        ciclo: ciclo.id,
        escalao: escalao.id,
        tipo_dono: tipoDono.id,
        tipo_edificio: tipoPredio.id,
        aceito_condicoes_gerais: otherInfo.aceito_condicoes_gerais,
        observacoes: otherInfo.observacoes,
        andar: otherInfo.andar,
        data_cessacao_contrato: dataCesContrato
          ? moment(dataCesContrato).format("YYYY-MM-DD")
          : null,
        data: moment(adesaoData).format("YYYY-MM-DD"),
        cpe: otherInfo.cpe,
        cui: otherInfo.cui,
        nipc: companyInfo.nipc,
        cae: companyInfo.cae,
        nome_empresa: companyInfo.nome_empresa,
        rep_legal: companyInfo.rep_legal,
        titular_contrato_anterior: titCon.id,
        nova_ligacao: newCon.id,
        fornecimento_energia: fornEne.id,
        inspecao_gas: inspGas.id,
        fornecimento_gas: fornGas.id,
        multiponto: multiponto.id,
        emp_nome_ind: empNomeInd.id,
        n_registo: upacInfo.n_registo,
        potencia_resp: upacInfo.potencia_resp,
        cpe_consumo: upacInfo.cpe_consumo,
        cpe_producao: upacInfo.cpe_producao,
        morada_upac: upacAddressInfo.morada_upac,
        cod_postal_upac: upacAddressInfo.cod_postal_upac,
        localidade_upac: upacAddressInfo.localidade_upac,
        concelho_upac: upacAddressInfo.concelho_upac,
        distrito_upac: upacAddressInfo.distrito_upac,
        observacoesPlus: otherInfo.observacoesPlus,
      },
    ];

    console.log(ydata);

    const errorMessage = {
      isOpen: true,
      message: "Ocorreu um erro!",
      type: "error",
    };
    const message = {
      isOpen: true,
      message: "Adesão editada com sucesso!!",
      type: "success",
    };

    dispatch(
      EditPlusAdesao(ydata, adesaoSelecionada.id_jaf_plus_client_form, message)
    )
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchAdesoes(servico.id, estado.id, date); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  const handleEmitirContrato = (e) => {
    e.preventDefault();

    const message = {
      isOpen: true,
      message: "Contrato emitido com sucesso!!",
      type: "success",
    };

    dispatch(EmitirContratoById(adesaoSelecionada.id_jaf_plus_client_form_data))
      .then(() => {
        toggleDrawer("right", false);
        dispatch(ShowNotification(message));
        fetchAdesoes(servico.id, estado.id, date); // Ensure fetchAdesoes is called here
      })
      .catch((error) => {
        console.error("Error updating adesao:", error); // Handle error
      });
  };

  return (
    <div
      className={classes.list}
      style={
        dimensions.width <= 1100
          ? { width: "60vw" }
          : { width: `${drawerWidth}` }
      }
    >
      <DrawerHistorico
        state={historicoState}
        toggleDrawer={toggleDrawerHistorico}
        drawerState={drawerStateHistorico}
        drawerWidth={drawerWidth}
        dimensions={dimensions}
        fetchAdesoes={fetchAdesoes}
        adesao={adesaoHistorico}
        setAdesao={setAdesaoHistorico}
        ciclos={ciclos}
        escaloes={escaloes}
        potencias={potencias}
        tiposTarifas={tiposTarifas}
        tiposDonos={tiposDonos}
        tiposEdificios={tiposEdificios}
        tiposDocs={tiposDocs}
        planos={planos}
        status={status}
        servico={servico}
        estado={estado}
        date={date}
      />
      <ModalFileDelete
        openDelete={openFileDelete}
        handleClose={handleCloseFileDelete}
        fileId={fileId}
        adesaoId={adesaoSelecionada.id_jaf_plus_client_form_data}
        clientId={adesaoSelecionada.id_jaf_plus_client_form}
        serviceId={adesaoSelecionada.id_jaf_plus_service_type}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <ModalAddFile
        openAddDocs={openAddDocs}
        handleCloseAddDoc={handleCloseAddDoc}
        fileId={fileId}
        adesaoId={adesaoSelecionada.id_jaf_plus_client_form_data}
        clientId={adesaoSelecionada.id_jaf_plus_client_form}
        serviceId={adesaoSelecionada.id_jaf_plus_service_type}
        tiposDocsAdesao={tiposDocsAdesao}
        tiposDocsCompExc={tiposDocsCompExc}
        // Loading
        loading={loading}
        setLoading={setLoading}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 5,
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => toggleDrawer("right", false)}
        >
          <div>
            <FeatherIcon
              icon="chevron-left"
              size={20}
              style={{ strokeWidth: "1.5" }}
            />
          </div>
          <div className="text-underline-close-drawer">Adesões</div>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={classes.btnFiltrar}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Box> */}
      </Box>
      <TabContext value={tabsvalue}>
        <Box sx={{ mx: 6, px: 2 }}>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#E21450",
              },
            }}
          >
            <Tab label="Formulário" value="1" className={classes.tab} />
            {user.permissoes.includes("APP_JAFPLUS_HISTORICO_CONTRATOS") && (
              <Tab label="Histórico" value="2" className={classes.tab} />
            )}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Card
            sx={{
              mx: 5,
              px: 2,
              boxShadow: "0px 10px 15px lightgrey",
            }}
          >
            <Box
              sx={{
                my: 2,
                mx: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4
                style={{
                  fontSize: "22px",
                  marginBottom: "0px",
                }}
                className="text-underline"
              >
                {adesaoSelecionada.id_jaf_plus_service_type == 1
                  ? "Dados Adesão"
                  : "Dados Compra de Excedentes"}
              </h4>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {user.permissoes.includes("APP_JAFPLUS_EMITIR_CONTRATOS") && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {adesaoSelecionada.jaf_plus_client_form_data_emited_at
                        ? `(Emitido em: ${moment(
                            adesaoSelecionada.jaf_plus_client_form_data_emited_at
                          ).format("DD/MM/YYYY")})`
                        : ""}
                    </Typography>
                    {adesaoSelecionada.jaf_plus_client_form_data_emited_flag ==
                      1 && (
                      <Button
                        sx={{
                          height: "40px",
                          borderRadius: "5px",
                          ml: 2,
                        }}
                        className={classes.btnUpload}
                        onClick={() => {
                          Instance()
                            .get(
                              `${BASE_URL}/plus/adesoes/downloadContrato/${adesaoSelecionada.contratoFileId}`,
                              { responseType: "blob" }
                            )
                            .then(({ data }) => {
                              console.log(data);
                              const filename =
                                "contrato_" +
                                adesaoSelecionada.jaf_plus_client_form_data_name +
                                ".pdf";
                              fileDownload(data, filename);
                            })
                            .catch((err) => {
                              console.log(err.response);
                              if (err.response) {
                                if (err.response === 403) {
                                  // dispatch(ShowNotification(errorMessage));
                                }
                              }
                            });
                        }}
                      >
                        <FeatherIcon icon="download" size={20} />
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className={classes.btnFiltrar}
                      sx={{ mx: 2 }}
                      onClick={handleEmitirContrato}
                    >
                      Emitir Contrato
                    </Button>
                  </Box>
                )}
                {user.permissoes.includes("APP_JAFPLUS_EDITAR_CONTRATOS") && (
                  <Button
                    variant="contained"
                    className={classes.btnFiltrar}
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  m: 1,
                  width: "48%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Responsável Comercial
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={responsaveis || []}
                    value={responsavel}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setResponsavel({ id: 0, name: "" });
                        return;
                      } else {
                        setResponsavel(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Estado
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={filteredStatus || []}
                    value={estadoAde}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setEstadoAde({ id: 0, name: "" });
                        return;
                      } else {
                        setEstadoAde(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  m: 1,
                  width: "48%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data de submissão
                  </Typography>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    value={adesaoData}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ width: "30%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Data da última atualização
                  </Typography>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    value={adesaoLastUpdateData}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
                <Box sx={{ width: "30%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Atualizado por
                  </Typography>
                  <TextField
                    disabled
                    size="small"
                    variant="outlined"
                    value={adesaoSelecionada.ultUtilizadorNome || "N/D"}
                    style={{ width: "100%" }}
                    autoComplete="off"
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  m: 1,
                  width: "48%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Parceiro Comercial
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={parceiros || []}
                    value={parceiro}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setParceiro({ id: 0, name: "" });
                        return;
                      } else {
                        setParceiro(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Observações Plus
              </Typography>
              <TextField
                name="observacoesPlus"
                size="small"
                variant="outlined"
                value={otherInfo.observacoesPlus}
                onChange={handleOtherInfoChange}
                style={{ width: "100%" }}
                autoComplete="off"
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    minRows: 3,
                    style: { width: "100%" },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mx: 1,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Dados Pessoais
              </Typography>
            </Box>
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_client_type == 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Nome
                    </Typography>
                    <TextField
                      name="nome"
                      size="small"
                      variant="outlined"
                      value={personalInfo.nome}
                      onChange={handlePersonalInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                  {/* <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Apelido
                </Typography>
                <TextField
                  name="apelido"
                  size="small"
                  variant="outlined"
                  value={personalInfo.apelido}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box> */}
                </Box>
              )}
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_client_type == 2 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Nome da empresa
                      </Typography>
                      <TextField
                        name="nome_empresa"
                        size="small"
                        variant="outlined"
                        value={companyInfo.nome_empresa}
                        onChange={handleCompanyInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Certidão Permanente
                      </Typography>
                      <TextField
                        name="certidao_permanente"
                        size="small"
                        variant="outlined"
                        value={docsInfo.certidao_permanente}
                        onChange={handleDocsInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        NIPC
                      </Typography>
                      <TextField
                        name="nipc"
                        size="small"
                        variant="outlined"
                        value={companyInfo.nipc}
                        onChange={handleCompanyInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        CAE
                      </Typography>
                      <TextField
                        name="cae"
                        size="small"
                        variant="outlined"
                        value={companyInfo.cae}
                        onChange={handleCompanyInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ m: 1, width: "48%" }}>
                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "10px" }}
                    >
                      Representante Legal
                    </Typography>
                    <TextField
                      name="rep_legal"
                      size="small"
                      variant="outlined"
                      value={companyInfo.rep_legal}
                      onChange={handleCompanyInfoChange}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                  </Box>
                </>
              )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Telefone
                </Typography>
                <TextField
                  name="telefone"
                  size="small"
                  variant="outlined"
                  value={personalInfo.telefone}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Telemóvel
                </Typography>
                <TextField
                  name="telemovel"
                  size="small"
                  variant="outlined"
                  value={personalInfo.telemovel}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Tipo Documento
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={tiposDocs || []}
                  value={tipoDoc}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTipoDoc({ id: 0, name: "" });
                      return;
                    } else {
                      setTipoDoc(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cliente Prioritário / Cliente com necessidades
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={prioridadeOptions || []}
                  value={prioCliente}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setPrioCliente({ id: 0, name: "" });
                      return;
                    } else {
                      setPrioCliente(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Nº Doc. Identificação
                </Typography>
                <TextField
                  name="numero_documento"
                  size="small"
                  variant="outlined"
                  value={docsInfo.numero_documento}
                  onChange={handleDocsInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "20%" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptLocale}
                >
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Validade Doc. Id.
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="dd/MM/yyyy"
                      okLabel="ok"
                      cancelLabel="cancelar"
                      invalidDateMessage="Data Inválida"
                      value={adesaoValiDoc}
                      onChange={handleAdesaoValiDoc}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/mm/aaaa",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
              <Box sx={{ m: 1, width: "25%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  NIF
                </Typography>
                <TextField
                  name="nif"
                  size="small"
                  variant="outlined"
                  value={docsInfo.nif}
                  onChange={handleDocsInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Email
                </Typography>
                <TextField
                  name="email"
                  size="small"
                  variant="outlined"
                  value={personalInfo.email}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  IBAN
                </Typography>
                <TextField
                  name="iban"
                  size="small"
                  variant="outlined"
                  value={docsInfo.iban}
                  onChange={handleDocsInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Tarifa Social
            </Typography>
            <TextField
              name="tarifaSocial"
              size="small"
              variant="outlined"
              value={adesao.tarifaSocial}
              onChange={handleChange}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Titular Contrato Anterior
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={YesNo || []}
                  value={titCon}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTitCont({ id: 0, name: "" });
                      return;
                    } else {
                      setTitCont(value);
                      return value;
                    }
                  }}
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Preferência de contacto
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={prefContactos || []}
                  value={prefContacto}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setPrefContacto({ id: 0, name: "" });
                      return;
                    } else {
                      setPrefContacto(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Envio de Faturação
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={envioFaturaOptions || []}
                  value={envioFaturacao}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setEnvioFaturacao({ id: 0, name: "" });
                      return;
                    } else {
                      setEnvioFaturacao(value);
                      return value;
                    }
                  }}
                />
              </Box>

              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Email de fatura
                </Typography>
                <TextField
                  name="email_fatura"
                  size="small"
                  variant="outlined"
                  value={personalInfo.email_fatura}
                  onChange={handlePersonalInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Modalidade de pagamento
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={modPagamentosOptions || []}
                  value={modPagamento}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setModPagamento({ id: 0, name: "" });
                      return;
                    } else {
                      setModPagamento(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_service_type == 2 && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    A morada fiscal é diferente do CPE
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={taxMorada}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setTaxMorada({ id: 0, name: "" });
                        return;
                      } else {
                        setTaxMorada(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Morada
                </Typography>
                <TextField
                  name="morada"
                  size="small"
                  variant="outlined"
                  value={addressInfo.morada}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Cód-Postal
                </Typography>
                <TextField
                  name="cod_postal"
                  size="small"
                  variant="outlined"
                  value={addressInfo.cod_postal}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Localidade
                </Typography>
                <TextField
                  name="localidade"
                  size="small"
                  variant="outlined"
                  value={addressInfo.localidade}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Concelho
                </Typography>
                <TextField
                  name="concelho"
                  size="small"
                  variant="outlined"
                  value={addressInfo.concelho}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Distrito
                </Typography>
                <TextField
                  name="distrito"
                  size="small"
                  variant="outlined"
                  value={addressInfo.distrito}
                  onChange={handleAddressInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            </Box>
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_service_type == 1 && (
                <>
                  <Box
                    sx={{
                      my: 2,
                      mx: 1,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Morada de correspondência
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Mesma morada do cliente
                      </Typography>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={YesNo || []}
                        value={mailMorada}
                        getOptionLabel={(option) => `${option.name}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, value, reason) => {
                          if (reason === "clear") {
                            setMailMorada({ id: 0, name: "" });
                            return;
                          } else {
                            setMailMorada(value);
                            return value;
                          }
                        }}
                      />
                    </Box>
                    {mailMorada.id == 0 && (
                      <Box sx={{ m: 1, width: "48%" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Morada
                        </Typography>
                        <TextField
                          name="morada_mail"
                          size="small"
                          variant="outlined"
                          value={addressMailInfo.morada_mail}
                          onChange={handleAddressMailInfoChange}
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      </Box>
                    )}
                  </Box>
                  {mailMorada.id == 0 && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Cód-Postal
                          </Typography>
                          <TextField
                            name="cod_postal_mail"
                            size="small"
                            variant="outlined"
                            value={addressMailInfo.cod_postal_mail}
                            onChange={handleAddressMailInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Localidade
                          </Typography>
                          <TextField
                            name="localidade_mail"
                            size="small"
                            variant="outlined"
                            value={addressMailInfo.localidade_mail}
                            onChange={handleAddressMailInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Concelho
                          </Typography>
                          <TextField
                            name="concelho_mail"
                            size="small"
                            variant="outlined"
                            value={addressMailInfo.concelho_mail}
                            onChange={handleAddressMailInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Distrito
                          </Typography>
                          <TextField
                            name="distrito_mail"
                            size="small"
                            variant="outlined"
                            value={addressMailInfo.distrito_mail}
                            onChange={handleAddressMailInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_service_type == 1 && (
                <>
                  <Box
                    sx={{
                      my: 2,
                      mx: 1,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "18px",
                      }}
                    >
                      Morada de fornecimento
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Mesma morada do cliente
                      </Typography>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={YesNo || []}
                        value={taxMorada}
                        getOptionLabel={(option) => `${option.name}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, value, reason) => {
                          if (reason === "clear") {
                            setTaxMorada({ id: 0, name: "" });
                            return;
                          } else {
                            setTaxMorada(value);
                            return value;
                          }
                        }}
                      />
                    </Box>
                    {taxMorada.id == 0 && (
                      <Box sx={{ m: 1, width: "48%" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Morada
                        </Typography>
                        <TextField
                          name="morada_tax"
                          size="small"
                          variant="outlined"
                          value={addressTaxInfo.morada_tax}
                          onChange={handleAddressTaxInfoChange}
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      </Box>
                    )}
                  </Box>
                  {taxMorada.id == 0 && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Cód-Postal
                          </Typography>
                          <TextField
                            name="cod_postal_tax"
                            size="small"
                            variant="outlined"
                            value={addressTaxInfo.cod_postal_tax}
                            onChange={handleAddressTaxInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Localidade
                          </Typography>
                          <TextField
                            name="localidade_tax"
                            size="small"
                            variant="outlined"
                            value={addressTaxInfo.localidade_tax}
                            onChange={handleAddressTaxInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Concelho
                          </Typography>
                          <TextField
                            name="concelho_tax"
                            size="small"
                            variant="outlined"
                            value={addressTaxInfo.concelho_tax}
                            onChange={handleAddressTaxInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                        <Box sx={{ m: 1, width: "48%" }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            Distrito
                          </Typography>
                          <TextField
                            name="distrito_tax"
                            size="small"
                            variant="outlined"
                            value={addressTaxInfo.distrito_tax}
                            onChange={handleAddressTaxInfoChange}
                            style={{ width: "100%" }}
                            autoComplete="off"
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}

            {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  CPE
                </Typography>
                <TextField
                  name="cpe"
                  size="small"
                  variant="outlined"
                  value={otherInfo.cpe}
                  onChange={handleOtherInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            )}
            {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  CUI
                </Typography>
                <TextField
                  name="cui"
                  size="small"
                  variant="outlined"
                  value={otherInfo.cui}
                  onChange={handleOtherInfoChange}
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </Box>
            )}

            {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Nova Ligação
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={newCon}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setNewCon({ id: 0, name: "" });
                        return;
                      } else {
                        setNewCon(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Fornecimento de Energia
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={fornEne}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setFornEne({ id: 0, name: "" });
                        return;
                      } else {
                        setFornEne(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Inspeção Gás
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={inspGas}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setInspGas({ id: 0, name: "" });
                        return;
                      } else {
                        setInspGas(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Fornecimento Gás
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={fornGas}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setFornGas({ id: 0, name: "" });
                        return;
                      } else {
                        setFornGas(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Multiponto
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={YesNo || []}
                  value={multiponto}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setMultiponto({ id: 0, name: "" });
                      return;
                    } else {
                      setMultiponto(value);
                      return value;
                    }
                  }}
                />
              </Box>
              {adesaoSelecionada.id_jaf_plus_client_type == 2 && (
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Empresário nome individual
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={YesNo || []}
                    value={empNomeInd}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setEmpNomeInd({ id: 0, name: "" });
                        return;
                      } else {
                        setEmpNomeInd(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_service_type == 1 && (
                <>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Tipo Cliente(Dono)
                      </Typography>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={tiposDonos || []}
                        value={tipoDono}
                        getOptionLabel={(option) => `${option.name}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, value, reason) => {
                          if (reason === "clear") {
                            setTipoDono({ id: 0, name: "" });
                            return;
                          } else {
                            setTipoDono(value);
                            return value;
                          }
                        }}
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Tipo Prédio
                      </Typography>
                      <Autocomplete
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Sem dados"
                        options={tiposEdificios || []}
                        value={tipoPredio}
                        getOptionLabel={(option) => `${option.name}` || ""}
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, value, reason) => {
                          if (reason === "clear") {
                            setTipoPredio({ id: 0, name: "" });
                            return;
                          } else {
                            setTipoPredio(value);
                            return value;
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Fração / Andar
                      </Typography>
                      <TextField
                        name="andar"
                        size="small"
                        variant="outlined"
                        value={otherInfo.andar}
                        onChange={handleOtherInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <Typography
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Data cessação contrato
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            value={dataCesContrato}
                            onChange={handleDataCessacaoContrato}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/mm/aaaa",
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                sx={{ width: "50%" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </>
              )}
            {adesaoSelecionada &&
              adesaoSelecionada.id_jaf_plus_service_type == 2 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                      mx: 1,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        fontSize: "20px",
                      }}
                    >
                      Dados UPAC
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Número de registo
                      </Typography>
                      <TextField
                        name="n_registo"
                        size="small"
                        variant="outlined"
                        value={upacInfo.n_registo}
                        onChange={handleUpacInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Potência de injeção na RESP (kVA)
                      </Typography>
                      <TextField
                        name="potencia_resp"
                        size="small"
                        variant="outlined"
                        value={upacInfo.potencia_resp}
                        onChange={handleUpacInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        CPE Consumo
                      </Typography>
                      <TextField
                        name="cpe_consumo"
                        size="small"
                        variant="outlined"
                        value={upacInfo.cpe_consumo}
                        onChange={handleUpacInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        CPE Produção
                      </Typography>
                      <TextField
                        name="cpe_producao"
                        size="small"
                        variant="outlined"
                        value={upacInfo.cpe_producao}
                        onChange={handleUpacInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Morada
                      </Typography>
                      <TextField
                        name="morada_upac"
                        size="small"
                        variant="outlined"
                        value={upacAddressInfo.morada_upac}
                        onChange={handleUpacAddressInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Cód-Postal
                      </Typography>
                      <TextField
                        name="cod_postal_upac"
                        size="small"
                        variant="outlined"
                        value={upacAddressInfo.cod_postal_upac}
                        onChange={handleUpacAddressInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Localidade
                      </Typography>
                      <TextField
                        name="localidade_upac"
                        size="small"
                        variant="outlined"
                        value={upacAddressInfo.localidade_upac}
                        onChange={handleUpacAddressInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Concelho
                      </Typography>
                      <TextField
                        name="concelho_upac"
                        size="small"
                        variant="outlined"
                        value={upacAddressInfo.concelho_upac}
                        onChange={handleUpacAddressInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ m: 1, width: "48%" }}>
                      <Typography
                        style={{ fontWeight: "bold", marginBottom: "10px" }}
                      >
                        Distrito
                      </Typography>
                      <TextField
                        name="distrito_upac"
                        size="small"
                        variant="outlined"
                        value={upacAddressInfo.distrito_upac}
                        onChange={handleUpacAddressInfoChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </>
              )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: 1,
                mt: 2,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontSize: "20px",
                }}
              >
                Plano
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Serviço
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={planos || []}
                  value={tarifPlan}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setTarifPlan({ id: 0, name: "" });
                      return;
                    } else {
                      setTarifPlan(value);
                      return value;
                    }
                  }}
                />
              </Box>
            </Box>
            {(tarifPlan.id == 2 || tarifPlan.id == 3 || tarifPlan.id == 6) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Tipo de Tarifa
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={filteredTarifas || []}
                    value={tarifa}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setTarifa({ id: 0, name: "" });
                        return;
                      } else {
                        setTarifa(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ m: 1, width: "22%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Potência
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={filteredPotencias || []}
                    value={potencia}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setPotencia({ id: 0, name: "" });
                        return;
                      } else {
                        setPotencia(value);
                        return value;
                      }
                    }}
                  />
                </Box>
                <Box sx={{ m: 1, width: "22%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Ciclo
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={filteredCiclos || []}
                    value={ciclo}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setCiclo({ id: 0, name: "" });
                        return;
                      } else {
                        setCiclo(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            {(tarifPlan.id == 5 || tarifPlan.id == 7) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* {tarifPlan.id == 5 && (
              <Box sx={{ m: 1, width: "48%" }}>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "10px" }}
                >
                  Ciclo
                </Typography>
                <Autocomplete
                  fullWidth
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  noOptionsText="Sem dados"
                  options={filteredCiclos || []}
                  value={ciclo}
                  getOptionLabel={(option) => `${option.name}` || ""}
                  isOptionEqualToValue={(option, value) => {
                    // if (!value.id) return true;
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" variant="outlined" />
                  )}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setCiclo(null);
                      return;
                    } else {
                      setCiclo(value);
                      return value;
                    }
                  }}
                />
              </Box>
            )} */}
                <Box sx={{ m: 1, width: "48%" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginBottom: "10px" }}
                  >
                    Escalão
                  </Typography>
                  <Autocomplete
                    fullWidth
                    clearText="Limpar"
                    openText="Abrir"
                    closeText="Fechar"
                    noOptionsText="Sem dados"
                    options={filteredEscaloes || []}
                    value={escalao}
                    getOptionLabel={(option) => `${option.name}` || ""}
                    isOptionEqualToValue={(option, value) => {
                      // if (!value.id) return true;
                      return value.id === option.id;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" variant="outlined" />
                    )}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setEscalao({ id: 0, name: "" });
                        return;
                      } else {
                        setEscalao(value);
                        return value;
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box sx={{ m: 1 }}>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Observações
              </Typography>
              <TextField
                name="observacoes"
                size="small"
                variant="outlined"
                value={otherInfo.observacoes}
                onChange={handleOtherInfoChange}
                style={{ width: "100%" }}
                autoComplete="off"
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    minRows: 3,
                    style: { width: "100%" },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mx: 1,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Documentos
              </Typography>
              <IconButton
                onClick={(e) => {
                  handleOpenAddDoc(e);
                }}
                style={{
                  padding: 6,
                  color: theme.palette.secondary.main,
                }}
              >
                <FeatherIcon
                  icon="plus-square"
                  size={20}
                  style={{ strokeWidth: "1.5" }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              {documentos.map((d) => (
                <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
                  <Typography>{d.name}</Typography>
                  {user.permissoes.includes(
                    "APP_JAFPLUS_DOWNLOAD_CONTRATOS_FICHEIROS"
                  ) && (
                    <Button
                      sx={{
                        height: "40px",
                        borderRadius: "5px",
                        mx: 2,
                      }}
                      className={classes.btnUpload}
                      onClick={() => {
                        // Define the file URL stored in your system
                        const fileUrl = `https://jafplus.pt/wp-content${d.path}`; // Replace with your actual file URL

                        // Create a temporary anchor element
                        const link = document.createElement("a");
                        link.href = fileUrl;

                        // Set the target attribute to open in a new window
                        link.setAttribute("target", "_blank");

                        // Append the link to the document body
                        document.body.appendChild(link);

                        // Programmatically click the link to open the file in a new window/tab
                        link.click();

                        // Remove the link after the operation
                        document.body.removeChild(link);
                      }}
                    >
                      <FeatherIcon icon="download" size={20} />
                    </Button>
                  )}
                  <IconButton
                    onClick={(e) => {
                      handleOpenFileDelete(e, d.id);
                    }}
                    style={{
                      padding: 6,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <FeatherIcon
                      icon="trash-2"
                      size={20}
                      style={{ strokeWidth: "1.5" }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Box>
            {user.permissoes.includes("APP_JAFPLUS_EDITAR_CONTRATOS") && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
                <Button
                  variant="contained"
                  className={classes.btnFiltrar}
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </Box>
            )}
            {/* <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Fatura Eletricidade
            </Typography>
            <TextField
              name="ynb_adesao_fatura_eleticidade_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_fatura_eleticidade_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box>
          <Box sx={{ m: 1, width: "48%" }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Comprovativo IBAN
            </Typography>
            <TextField
              name="ynb_adesao_comprovativo_iban_file"
              size="small"
              variant="outlined"
              value={adesao.ynb_adesao_comprovativo_iban_file}
              style={{ width: "100%" }}
              autoComplete="off"
            />
          </Box> */}
          </Card>
        </TabPanel>
        <TabPanel value="2">
          <Card
            sx={{
              mx: 5,
              px: 2,
              boxShadow: "0px 10px 15px lightgrey",
            }}
          >
            <Box
              sx={{
                my: 2,
                mx: 1,
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    className="text-underline"
                  >
                    Histórico de contratos
                  </span>
                }
                data={historico || []}
                columns={columns}
                options={options}
              />
            </Box>
          </Card>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Form;
